import Stats from 'static/files/stats-web.umd.v2.4.3.js';

// apm注册的app唯一标识
const APM_APP_CODE = 'ab31cc6dfde84024aae62c02e8f1b13d';

const projectEnv = process.env.PROJECT_ENV;

/**
 * 初始化神策
 */
function initSensor() {
  Stats.init({
    system_code: 'SCC-OMS', // 系统编码
    product_code: '', // product_code必填（用来区分OMS下不同的应用的，现在就一个，不用写）
    platform_env: process.env.PROJECT_ENV, // 环境dev/sit/prod
    app_version: '1.0.0', // 项目版本号，方便排查问题
    show_log: true, // 是否打印log
    server_url: 'https://ubs.sf-express.com/sa?project=fxb', // 神策上报地址
    sensorsConfig: {
      heatmap: { scroll_notice_map: 'not_collect' },
      is_track_single_page: true,
      use_client_time: true,
      send_type: 'beacon',
      batch_send: false,
    },
    plugins: {
      jsError: {
        // 这里屏蔽掉不想上报的错误，支持字符串和正则匹配
        ignoreErrors: [/^Request failed with status code/, /^timeout of .* exceeded$/, /^ResizeObserver loop/],
      },
      sfapm: {
        // APM中的appCode
        appCode: APM_APP_CODE,
        // SDK已判断”请求返回非200|401|403“或者”返回的数据中success不为true并且不是未登录场景“，这里是进一步判断接口是否需要上报，return true表示要上报
        // 只有SDK判定要上报 且 这里校验要上报 才会上报
        canCatchHttp200(params: Record<string, unknown>) {
          const url = ((params || {}).url as string) || '';
          const { status } = params || {};
          // 需要排除的域名
          const whiteList = [/sockjs-node\/info/];
          // url是否在白名单中
          function isWhiteList(_url: string) {
            for (let i = 0; i < whiteList.length; i++) {
              if (whiteList[i].test(_url)) {
                return true;
              }
            }
            return false;
          }

          if (isWhiteList(url)) {
            return false;
          }

          // status为200 不上报APM
          if (status === 200) {
            return false;
          }

          return true;
        },
      },
    },
  });

  // 注册公共属性，这里注册一下我们需要的 platform_type 和 platform_name字段
  Stats.registerCommonParams({
    platform_type: 'Web', // 应用平台类型
    platform_name: '',
  });
}

if (['online'].includes(projectEnv)) {
  initSensor();
}

export default Stats;
