/*
 * 增加海外部署之后，权限不能只通过online、uat来区分了，需要根据域名来区分
 */
import onlineAuth from './auth.online.conf';
// import testAuth from './auth.test.conf';
import uatAuth from './auth.uat.conf';
// import onlineOSAuth from './auth.online-os.conf';
import uatOSAuth from './auth.uat-os.conf';

// uat、test环境权限没有这么严格，所以key不会和online一一对应，这里用Partial处理一下
type TAuthMap = Partial<typeof onlineAuth>;

const { hostname } = window.location;

const authMap = {
  'scc-oms.sf-express.com': onlineAuth, // 国内生产环境
  'uat-scc-oms.sftcwl.com.cn': uatAuth as TAuthMap, // 国内uat环境
  'scc-oms-sit.th.kex-express.com': uatOSAuth as TAuthMap, // 海外sit环境
  // localhost: testAuth as TAuthMap, // 本地开发
  localhost: uatAuth as TAuthMap, // 本地开发借用uat的权限
};

export default authMap[hostname as keyof typeof authMap];
