import { defineMessages } from 'react-intl';
import { GLOBAL_MSG_SCOPE } from 'utils/constants';
// 与路由保持一致
export const scope = GLOBAL_MSG_SCOPE;

export default defineMessages({
  /** 通用词条 start */
  reset: {
    id: `${scope}.reset`,
    defaultMessage: '重置',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: '清空',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: '保存',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: '关闭',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: '确定',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: '刷新',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: '说明',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: '地址',
  },
  detailedAddress: {
    id: `${scope}.detailedAddress`,
    defaultMessage: '详细地址',
  },
  longitude: {
    id: `${scope}.longitude`,
    defaultMessage: '经度',
  },
  latitude: {
    id: `${scope}.latitude`,
    defaultMessage: '纬度',
  },
  timeZone: {
    id: `${scope}.timeZone`,
    defaultMessage: '时区',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: '全部',
  },
  query: {
    id: `${scope}.query`,
    defaultMessage: '查询',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: '搜索',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: '查看',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: '新建',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: '复制',
  },
  operate: {
    id: `${scope}.operate`,
    defaultMessage: '操作',
  },
  detail: {
    id: `${scope}.detail`,
    defaultMessage: '详情',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: '编辑',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: '删除',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: '确认',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: '取消',
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: '下载',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: '新增',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: '移除',
  },
  empty: {
    id: `${scope}.empty`,
    defaultMessage: '空',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: '继续',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: '提交',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: '成功',
  },
  failed: {
    id: `${scope}.failed`,
    defaultMessage: '失败',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: '导入',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: '导出',
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage: '备注',
  },
  china: {
    id: `${scope}.china`,
    defaultMessage: '中国',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: '国家/地区',
  },
  province: {
    id: `${scope}.province`,
    defaultMessage: '省份',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: '城市',
  },
  county: {
    id: `${scope}.county`,
    defaultMessage: '区/县',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: '币种',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: '金额',
  },
  otherRegion: {
    id: `${scope}.otherRegion`,
    defaultMessage: '其他区',
  },
  town: {
    id: `${scope}.town`,
    defaultMessage: '街道/乡镇',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: '状态',
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: '必填项不能为空',
  },
  inputPlaceholder: {
    id: `${scope}.inputPlaceholder`,
    defaultMessage: '请输入',
  },
  selectPlaceholder: {
    id: `${scope}.selectPlaceholder`,
    defaultMessage: '请选择',
  },
  multipleSelectPlaceholder: {
    id: `${scope}.multipleSelectPlaceholder`,
    defaultMessage: '支持多选，请选择',
  },
  log: {
    id: `${scope}.log`,
    defaultMessage: '日志',
  },
  day: {
    id: `${scope}.day`,
    defaultMessage: '天',
  },
  hour: {
    id: `${scope}.hour`,
    defaultMessage: '小时',
  },
  minute: {
    id: `${scope}.minute`,
    defaultMessage: '分钟',
  },
  second: {
    id: `${scope}.second`,
    defaultMessage: '秒',
  },
  paramsError: {
    id: `${scope}.paramsError`,
    defaultMessage: '参数错误',
  },
  networkError: {
    id: `${scope}.networkError`,
    defaultMessage: '网络错误，请稍候再试',
  },
  operateSuccess: {
    id: `${scope}.operateSuccess`,
    defaultMessage: '操作成功',
  },
  operateFailed: {
    id: `${scope}.operateFailed`,
    defaultMessage: '操作失败，请稍候再试',
  },
  userInfoError: {
    id: `${scope}.userInfoError`,
    defaultMessage: '用户信息异常，请在主应用中确认是否登录',
  },
  version: {
    id: `${scope}.version`,
    defaultMessage: '版本',
  },
  /** 通用词条 end */

  /** 业务常用词条 start */
  systemName: {
    id: `${scope}.systemName`,
    defaultMessage: '百川OMS',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: '货主',
  },
  company_code: {
    id: `${scope}.company_code`,
    defaultMessage: '货主编码',
  },
  company_name: {
    id: `${scope}.company_name`,
    defaultMessage: '货主名称',
  },
  company_short_name: {
    id: `${scope}.company_short_name`,
    defaultMessage: '货主简称',
  },
  warehouse: {
    id: `${scope}.warehouse`,
    defaultMessage: '仓库',
  },
  warehouse_code: {
    id: `${scope}.warehouse_code`,
    defaultMessage: '仓库编码',
  },
  warehouse_name: {
    id: `${scope}.warehouse_name`,
    defaultMessage: '仓库名称',
  },
  dept: {
    id: `${scope}.dept`,
    defaultMessage: '网点',
  },
  dept_code: {
    id: `${scope}.dept_code`,
    defaultMessage: '网点编码',
  },
  dept_name: {
    id: `${scope}.dept_name`,
    defaultMessage: '网点名称',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: '供应商',
  },
  supplier_code: {
    id: `${scope}.supplier_code`,
    defaultMessage: '供应商编码',
  },
  supplier_name: {
    id: `${scope}.supplier_name`,
    defaultMessage: '供应商名称',
  },
  carrier: {
    id: `${scope}.carrier`,
    defaultMessage: '承运商',
  },
  carrier_code: {
    id: `${scope}.carrier_code`,
    defaultMessage: '承运商编码',
  },
  carrier_name: {
    id: `${scope}.carrier_name`,
    defaultMessage: '承运商名称',
  },
  carrier_product: {
    id: `${scope}.carrier_product`,
    defaultMessage: '承运商产品',
  },
  waybill: {
    id: `${scope}.waybill`,
    defaultMessage: '运单',
  },
  waybill_no: {
    id: `${scope}.waybill_no`,
    defaultMessage: '运单号',
  },
  order_status: {
    id: `${scope}.order_status`,
    defaultMessage: '订单状态',
  },
  order_type: {
    id: `${scope}.order_type`,
    defaultMessage: '订单类型',
  },
  erp_order_no: {
    id: `${scope}.erp_order_no`,
    defaultMessage: 'ERP单号',
  },
  oms_order_no: {
    id: `${scope}.oms_order_no`,
    defaultMessage: 'OMS订单号',
  },
  sku: {
    id: `${scope}.sku`,
    defaultMessage: '商品',
  },
  sku_code: {
    id: `${scope}.sku_code`,
    defaultMessage: '商品编码',
  },
  sku_name: {
    id: `${scope}.sku_name`,
    defaultMessage: '商品名称',
  },
  create_time: {
    id: `${scope}.create_time`,
    defaultMessage: '创建时间',
  },
  create_emp: {
    id: `${scope}.create_emp`,
    defaultMessage: '创建人',
  },
  update_time: {
    id: `${scope}.update_time`,
    defaultMessage: '更新时间',
  },
  update_emp: {
    id: `${scope}.update_emp`,
    defaultMessage: '更新人',
  },
  errorLogId: {
    id: `${scope}.errorLogId`,
    defaultMessage: '当前操作的 LogId：{logId}',
  },
  /** 业务常用词条 end */

  station: {
    id: `${scope}.station`,
    defaultMessage: '站点',
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: '品牌',
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: '大区',
  },
  paginationTotal: {
    id: `${scope}.paginationTotal`,
    defaultMessage: '共{total}项',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: '退出登录',
  },
  editpwd: {
    id: `${scope}.editpwd`,
    defaultMessage: '修改密码',
  },
  maxCharacter: {
    id: `${scope}.maxCharacter`,
    defaultMessage: '不能超过{max}个字符',
  },
  emptyTable: {
    id: `${scope}.emptyTable`,
    defaultMessage: '暂无数据',
  },
  downLoadTemplate: {
    id: `${scope}.downLoadTemplate`,
    defaultMessage: '下载模板',
  },
  conditionExport: {
    id: `${scope}.conditionExport`,
    defaultMessage: '条件导出',
  },
  overLength: {
    id: `${scope}.overLength`,
    defaultMessage: '超出最大{max}个字符限制',
  },
  inputPlaceholderWithLength: {
    id: `${scope}.inputPlaceholderWithLength`,
    defaultMessage: '请输入，最多支持{max}个字符',
  },
  sugPlaceholder: {
    id: `${scope}.sugPlaceholder`,
    defaultMessage: '搜索后选择，支持模糊搜索',
  },
  // 日志相关
  operator_log: {
    id: `${scope}.operator_log`,
    defaultMessage: '操作日志',
  },
  operator: {
    id: `${scope}.operator`,
    defaultMessage: '操作者',
  },
  operator_time: {
    id: `${scope}.operator_time`,
    defaultMessage: '操作时间',
  },
  operator_type: {
    id: `${scope}.operator_type`,
    defaultMessage: '操作类型',
  },
  operator_content: {
    id: `${scope}.operator_content`,
    defaultMessage: '操作内容',
  },
  index: {
    id: `${scope}.index`,
    defaultMessage: '序号',
  },
  moveUp: {
    id: `${scope}.moveUp`,
    defaultMessage: '上移',
  },
  moveDown: {
    id: `${scope}.moveDown`,
    defaultMessage: '下移',
  },
  saveAndClose: {
    id: `${scope}.saveAndClose`,
    defaultMessage: '保存',
  },
  provinceCityDistrict: {
    id: `${scope}.provinceCityDistrict`,
    defaultMessage: '省市区',
  },
  chooseCountryProvinceCity: {
    id: `${scope}.chooseCountryProvinceCity`,
    defaultMessage: '请选择国家省市区',
  },
  chooseAddress: {
    id: `${scope}.chooseAddress`,
    defaultMessage: '请输入详细地址',
  },
  confirmDeleteTips: {
    id: `${scope}.confirmDeleteTips`,
    defaultMessage: '确认删除吗?',
  },
  confirmCancelTips: {
    id: `${scope}.confirmCancelTips`,
    defaultMessage: '确认取消吗?',
  },
  popConfirmTip: {
    id: `${scope}.popConfirmTip`,
    defaultMessage: '确认要进行该操作吗？',
  },
  asyncImportRes: {
    id: `${scope}.asyncImportRes`,
    defaultMessage: '文件导入成功，请在【任务中心】查看ID为 {task_id} 的任务数据',
  },
  asyncExportRes: {
    id: `${scope}.asyncExportRes`,
    defaultMessage: '文件导出成功，请在【任务中心】查看ID为 {task_id} 的任务数据',
  },
  exportNull: {
    id: `${scope}.exportNull`,
    defaultMessage: '该条件下没有可以导出的数据',
  },
  conditionExportTip: {
    id: `${scope}.conditionExportTip`,
    defaultMessage: '请在选择条件并搜索之后再进行条件导出',
  },
  sysError: {
    id: `${scope}.sysError`,
    defaultMessage: '系统错误',
  },
  tableHeaderConf: {
    id: `${scope}.tableHeaderConf`,
    defaultMessage: '表头配置',
  },
  batchSelected: {
    id: `${scope}.batchSelected`,
    defaultMessage: '已选择【{num}】条数据，单次最多可导出{max}条数据',
  },
  selectedEmptyWarning: {
    id: `${scope}.selectedEmptyWarning`,
    defaultMessage: '请勾选列表数据',
  },
  pushOrder: {
    id: `${scope}.pushOrder`,
    defaultMessage: '下发',
  },
  move: {
    id: `${scope}.move`,
    defaultMessage: '移动',
  },
  moveTips: {
    id: `${scope}.moveTips`,
    defaultMessage: '长按“移动”按钮可调整列表顺序',
  },
  timeSearchTips: {
    id: `${scope}.timeSearchTips`,
    defaultMessage: '只能查询30天以内的数据，请重新选择时间',
  },
  timeSearchThirtyOneTips: {
    id: `${scope}.timeSearchThirtyOneTips`,
    defaultMessage: '只能查询31天以内的数据，请重新选择时间',
  },
  selectedTotal: {
    id: `${scope}.selectedTotal`,
    defaultMessage: '已选 {total} 条',
  },
  selectNotFoundContent: {
    id: `${scope}.selectNotFoundContent`,
    defaultMessage: '暂无数据，请输入/修改关键词',
  },
  searchSelectPlaceholder: {
    id: `${scope}.searchSelectPlaceholder`,
    defaultMessage: '请选择或搜索',
  },
  mobilePhoneNumber: {
    id: `${scope}.mobilePhoneNumber`,
    defaultMessage: '手机号',
  },
  telephoneNumber: {
    id: `${scope}.telephoneNumber`,
    defaultMessage: '电话号',
  },
  else: {
    id: `${scope}.else`,
    defaultMessage: '其他',
  },
  systemError: {
    id: `${scope}.systemError`,
    defaultMessage: '抱歉，系统暂时异常，请稍后再试。',
  },
  uTry: {
    id: `${scope}.uTry`,
    defaultMessage: '您可以尝试',
  },
  contactForSup: {
    id: `${scope}.contactForSup`,
    defaultMessage: '若始终无法正常访问，请联系项目支持人员，感谢配合~',
  },
  moveErrorTip: {
    id: `${scope}.moveErrorTip`,
    defaultMessage: '仅同类型的规则之间可以移动调整优先级',
  },
  msgFormatError: {
    id: `${scope}.msgFormatError`,
    defaultMessage: '报文格式错误，请重新编辑',
  },
  copySuccess: {
    id: `${scope}.copySuccess`,
    defaultMessage: '复制成功',
  },
  inputByHand: {
    id: `${scope}.inputByhand`,
    defaultMessage: '不存在的选项可手动输入',
  },
  pageNotFound: {
    id: `${scope}.pageNotFound`,
    defaultMessage: '该页面不存在',
  },
  noAuth: {
    id: `${scope}.noAuth`,
    defaultMessage: '如没有页面权限, 请联系管理员',
  },
  user_def: {
    id: `${scope}.user_def`,
    defaultMessage: '预留字段{key}',
  },
  newVersion: {
    id: `${scope}.newVersion`,
    defaultMessage: '版本更新',
  },
  newVersionInfo: {
    id: `${scope}.newVersionInfo`,
    defaultMessage: '新版本已于{buildDate}发布，请刷新页面。如果页面没有更新，请尝试清除浏览器缓存并重新加载',
  },
  earlier: {
    id: `${scope}.earlier`,
    defaultMessage: '稍早前',
  },
  later: {
    id: `${scope}.later`,
    defaultMessage: '稍后',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: '基本信息',
  },
  screening_condition: {
    id: `${scope}.screening_condition`,
    defaultMessage: '筛选条件',
  },
  logical_operator: {
    id: `${scope}.logical_operator`,
    defaultMessage: '逻辑运算符',
  },
  field: {
    id: `${scope}.field`,
    defaultMessage: '字段',
  },
  comparison_operator: {
    id: `${scope}.comparison_operator`,
    defaultMessage: '条件运算符',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: '值',
  },
  and: {
    id: `${scope}.and`,
    defaultMessage: '且',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: '或',
  },
  maxSelectCount: {
    id: `${scope}.maxSelectCount`,
    defaultMessage: '最大可选条数为',
  },
});

/** 系统菜单message */
export const menuMessages = defineMessages<string>({
  homePage: {
    id: `${scope}.menu.homePage`,
    defaultMessage: '首页',
  },
  sysParamsConfig: {
    id: `${scope}.menu.sysParamsConfig`,
    defaultMessage: '系统参数设置',
  },
  onRuleCenter: {
    id: `${scope}.menu.onRuleCenter`,
    defaultMessage: '规则中心',
  },
  onInboundCenter: {
    id: `${scope}.menu.onInboundCenter`,
    defaultMessage: '入库管理',
  },
  onOutboundCenter: {
    id: `${scope}.menu.onOutboundCenter`,
    defaultMessage: '出库管理',
  },
  auth: {
    id: `${scope}.menu.auth`,
    defaultMessage: '权限管理',
  },
  subWarehouseRule: {
    id: `${scope}.menu.subWarehouseRule`,
    defaultMessage: '分仓规则',
  },
  goodsManagement: {
    id: `${scope}.menu.goodsManagement`,
    defaultMessage: '商品管理',
  },
  bOMManage: {
    id: `${scope}.menu.bOMManage`,
    defaultMessage: 'BOM管理',
  },
  adminRuleManage: {
    id: `${scope}.menu.adminRuleManage`,
    defaultMessage: '管控规则',
  },
  basicCodeConfig: {
    id: `${scope}.menu.basicCodeConfig`,
    defaultMessage: '基础代码',
  },
  inboundManage: {
    id: `${scope}.menu.inboundManage`,
    defaultMessage: '入库单管理',
  },
  inboundDetailPage: {
    id: `${scope}.menu.inboundDetailPage`,
    defaultMessage: '入库单管理详情',
  },
  inboundNoManage: {
    id: `${scope}.menu.inboundNoManage`,
    defaultMessage: '入库序列号查询',
  },
  inboundDetailManage: {
    id: `${scope}.menu.inboundDetailManage`,
    defaultMessage: '入库单明细查询',
  },
  orderManage: {
    id: `${scope}.menu.orderManage`,
    defaultMessage: '出库单处理',
  },
  companyManage: {
    id: `${scope}.menu.companyManage`,
    defaultMessage: '货主管理',
  },
  roleManage: {
    id: `${scope}.menu.roleManage`,
    defaultMessage: '角色管理',
  },
  strategyTimeRules: {
    id: `${scope}.menu.strategyTimeRules`,
    defaultMessage: '时效规则',
  },
  effectiveRules: {
    id: `${scope}.menu.effectiveRules`,
    defaultMessage: '极效规则',
  },
  nodeRule: {
    id: `${scope}.menu.nodeRule`,
    defaultMessage: '节点规则',
  },
  companyGroupManage: {
    id: `${scope}.menu.companyGroupManage`,
    defaultMessage: '货主组管理',
  },
  basicData: {
    id: `${scope}.menu.basicData`,
    defaultMessage: '基础数据',
  },
  warehouseManage: {
    id: `${scope}.menu.warehouseManage`,
    defaultMessage: '仓库管理',
  },
  companyWarehouseStockManage: {
    id: `${scope}.menu.companyWarehouseStockManage`,
    defaultMessage: '货主仓库库存管理',
  },
  companyStockmanage: {
    id: `${scope}.menu.companyStockmanage`,
    defaultMessage: '货主库存管理',
  },
  functionManage: {
    id: `${scope}.menu.functionManage`,
    defaultMessage: '功能权限管理',
  },
  taskCenter: {
    id: `${scope}.menu.taskCenter`,
    defaultMessage: '任务中心',
  },
  transOrderManage: {
    id: `${scope}.menu.transOrderManage`,
    defaultMessage: '配送管理',
  },
  distributeManage: {
    id: `${scope}.menu.distributeManage`,
    defaultMessage: '调拨单管理',
  },
  transOrderDetail: {
    id: `${scope}.menu.transOrderDetail`,
    defaultMessage: '配送管理详情',
  },
  stockCenter: {
    id: `${scope}.menu.stockCenter`,
    defaultMessage: '库存中心',
  },
  transactionFlow: {
    id: `${scope}.menu.transactionFlow`,
    defaultMessage: '库存交易流水',
  },
  outOrderSearch: {
    id: `${scope}.menu.outOrderSearch`,
    defaultMessage: '出库单查询',
  },
  outOrderDetail: {
    id: `${scope}.menu.outOrderDetail`,
    defaultMessage: '出库单详情',
  },
  stockInnerDiffBill: {
    id: `${scope}.menu.stockInnerDiffBill`,
    defaultMessage: '内部对账差异',
  },
  stockPicture: {
    id: `${scope}.menu.stockPicture`,
    defaultMessage: '库存快照',
  },
  lackSku: {
    id: `${scope}.menu.lackSku`,
    defaultMessage: '缺货信息查询',
  },
  inventoryReconciliation: {
    id: `${scope}.menu.inventoryReconciliation`,
    defaultMessage: '库存对账',
  },
  inventoryVersionDetail: {
    id: `${scope}.menu.inventoryVersionDetail`,
    defaultMessage: '库存版本明细',
  },
  orderSplit: {
    id: `${scope}.menu.orderSplit`,
    defaultMessage: '拆单管理',
  },
  announceManage: {
    id: `${scope}.menu.announceManage`,
    defaultMessage: '公告管理',
  },
  publishManage: {
    id: `${scope}.menu.publishManage`,
    defaultMessage: '发布管理',
  },
  splitOrderRule: {
    id: `${scope}.menu.splitOrderRule`,
    defaultMessage: '拆单规则',
  },
  orderMerge: {
    id: `${scope}.menu.orderMerge`,
    defaultMessage: '合单管理',
  },
  supplierManage: {
    id: `${scope}.menu.supplierManage`,
    defaultMessage: '供应商管理',
  },
  invBatchRule: {
    id: `${scope}.menu.invBatchRule`,
    defaultMessage: '库存推送规则',
  },
  interfaceModuleConfig: {
    id: `${scope}.menu.interfaceModuleConfig`,
    defaultMessage: '接口模板配置',
  },
  monitorCenter: {
    id: `${scope}.menu.monitorCenter`,
    defaultMessage: '监控管理',
  },
  exceptMessage: {
    id: `${scope}.menu.exceptMessage`,
    defaultMessage: '异常报文监控',
  },
  skuAudit: {
    id: `${scope}.menu.skuAudit`,
    defaultMessage: '商品审核',
  },
  inventoryAudit: {
    id: `${scope}.menu.inventoryAudit`,
    defaultMessage: '盘点审核',
  },
  wmsAddressConfig: {
    id: `${scope}.menu.wmsAddressConfig`,
    defaultMessage: 'WMS地址配置',
  },
  wmsSysCodeDockingConfig: {
    id: `${scope}.menu.wmsSysCodeDockingConfig`,
    defaultMessage: 'WMS对接配置',
  },
  orderCorrespondDeliveryTime: {
    id: `${scope}.menu.orderCorrespondDeliveryTime`,
    defaultMessage: '订单对应出库时间',
  },
  specialEffectiveSendConf: {
    id: `${scope}.menu.specialEffectiveSendConf`,
    defaultMessage: '特殊时效发送配置',
  },
  helpCenter: {
    id: `${scope}.menu.helpCenter`,
    defaultMessage: '帮助中心',
  },
  interceptRule: {
    id: `${scope}.menu.interceptRule`,
    defaultMessage: '拦截调用规则',
  },
  exceptData: {
    id: `${scope}.menu.exceptData`,
    defaultMessage: '异常数据监控',
  },
  importModuleConfig: {
    id: `${scope}.menu.importModuleConfig`,
    defaultMessage: '导入模板配置',
  },
  prePayRule: {
    id: `${scope}.menu.prePayRule`,
    defaultMessage: '预付款规则',
  },
  collectTransCenter: {
    id: `${scope}.menu.collectTransCenter`,
    defaultMessage: '集运管理',
  },
  collectOrderPage: {
    id: `${scope}.menu.collectOrderPage`,
    defaultMessage: '集运单管理',
  },
  collectOrderDetailPage: {
    id: `${scope}.menu.collectOrderDetailPage`,
    defaultMessage: '集运单详情',
  },
  unknownManage: {
    id: `${scope}.menu.unknownManage`,
    defaultMessage: '无主件识别处理',
  },
  importItemSearch: {
    id: `${scope}.menu.importItemSearch`,
    defaultMessage: '国际进口件查询',
  },
  customsNameMaintenance: {
    id: `${scope}.menu.customsNameMaintenance`,
    defaultMessage: '关务品名维护',
  },
  selfServiceStation: {
    id: `${scope}.menu.selfServiceStation`,
    defaultMessage: '自提网点维护',
  },
  suburbanPartsManagement: {
    id: `${scope}.menu.suburbanPartsManagement`,
    defaultMessage: '郊区件管理',
  },
  platformManage: {
    id: `${scope}.menu.platformManage`,
    defaultMessage: '月台预约单查询',
  },
  allocationDetail: {
    id: `${scope}.menu.allocationDetail`,
    defaultMessage: '调拨关联明细录入',
  },
  routingOperationCodeRule: {
    id: `${scope}.menu.routingOperationCodeRule`,
    defaultMessage: '模拟路由操作规则',
  },
  outboundTemperatureAndHumidity: {
    id: `${scope}.menu.outboundTemperatureAndHumidity`,
    defaultMessage: '出库温湿度管理',
  },
  orderWanted: {
    id: `${scope}.menu.orderWanted`,
    defaultMessage: '运单通缉查询',
  },
  bindRulesDetail: {
    id: `${scope}.drawerMap.bindRulesDetail`,
    defaultMessage: '规则绑定详情',
  },
  purchaseSaleManage: {
    id: `${scope}.menu.purchaseSaleManage`,
    defaultMessage: '进销存管理',
  },
  processOrder: {
    id: `${scope}.menu.processOrder`,
    defaultMessage: '加工单管理',
  },
  processOrderDetail: {
    id: `${scope}.menu.processOrderDetail`,
    defaultMessage: '加工单详情',
  },
  messageRepush: {
    id: `${scope}.menu.messageRepush`,
    defaultMessage: '报文重推',
  },
  storeManage: {
    id: `${scope}.menu.storeManage`,
    defaultMessage: '门店管理',
  },
  purchaseManagement: {
    id: `${scope}.menu.purchaseManagement`,
    defaultMessage: 'PO采购订单管理',
  },
  purchaseManagementDetail: {
    id: `${scope}.menu.purchaseManagementDetail`,
    defaultMessage: 'PO采购订单管理详情',
  },
  dynamicScale: {
    id: `${scope}.menu.dynamicScale`,
    defaultMessage: '动态称误差范围',
  },
  saleOrder: {
    id: `${scope}.menu.saleOrder`,
    defaultMessage: '销售订单管理',
  },
  originalOrderManage: {
    id: `${scope}.menu.originalOrderManage`,
    defaultMessage: '销售原单管理',
  },
  originalOrderDetail: {
    id: `${scope}.menu.originalOrderDetail`,
    defaultMessage: '销售原单详情',
  },
  shopManage: {
    id: `${scope}.menu.shopManage`,
    defaultMessage: '店铺管理',
  },
  saleOrderManage: {
    id: `${scope}.menu.saleOrderManage`,
    defaultMessage: '销售订单管理',
  },
  saleOrderDetail: {
    id: `${scope}.menu.saleOrderDetail`,
    defaultMessage: '销售订单详情',
  },
  timeRuleFlowConfig: {
    id: `${scope}.menu.timeRuleFlowConfig`,
    defaultMessage: '时效规则流向配置',
  },
  markingRule: {
    id: `${scope}.menu.markingRule`,
    defaultMessage: '打标规则',
  },
  kACustomerMarking: {
    id: `${scope}.menu.kACustomerMarking`,
    defaultMessage: 'KA客户打标',
  },
  orderSortRule: {
    id: `${scope}.menu.orderSortRule`,
    defaultMessage: '订单处理顺序规则',
  },
  // -- APPEND HERE --
  // FIXME: 上面的标识用于plop模板匹配,请勿轻易修改或者删除,合并时也请将语句置于句尾
});

/** 1-是、2-否 */
export const yesOrNoMessages = defineMessages<string | number>({
  1: {
    id: `${scope}.yesOrNo.1`,
    defaultMessage: '是',
  },
  2: {
    id: `${scope}.yesOrNo.2`,
    defaultMessage: '否',
  },
});

/** 1-是、0-否 */
export const zeroAndOneYesOrNoMessages = defineMessages<string | number>({
  1: {
    id: `${scope}.zeroAndOneYesOrNo.1`,
    defaultMessage: '是',
  },
  0: {
    id: `${scope}.zeroAndOneYesOrNo.0`,
    defaultMessage: '否',
  },
});

/** 1-是、2-否，3-空 */
export const yesNoNullMessages = defineMessages<string | number>({
  1: {
    id: `${scope}.yesNoNull.1`,
    defaultMessage: '是',
  },
  2: {
    id: `${scope}.yesNoNull.2`,
    defaultMessage: '否',
  },
  3: {
    id: `${scope}.yesNoNull.3`,
    defaultMessage: '空',
  },
});

/** 1-生效、2-失效 */
export const statusMessages = defineMessages<string | number>({
  1: {
    id: `${scope}.status.1`,
    defaultMessage: '生效',
  },
  2: {
    id: `${scope}.status.2`,
    defaultMessage: '失效',
  },
});

/** 1-成功、2-失败 */
export const successOrFailMap = defineMessages<string | number>({
  1: {
    id: `${scope}.successOrFailMap.1`,
    defaultMessage: '成功',
  },
  2: {
    id: `${scope}.successOrFailMap.2`,
    defaultMessage: '失败',
  },
});

export const isControlType = defineMessages({
  1: {
    id: `${scope}.isControlType.1`,
    defaultMessage: '订单拦截',
  },
  2: {
    id: `${scope}.isControlType.2`,
    defaultMessage: '不可下发',
  },
  3: {
    id: `${scope}.isControlType.3`,
    defaultMessage: '不自动下发',
  },
  4: {
    id: `${scope}.isControlType.4`,
    defaultMessage: '延时下发',
  },
});

/** 头部右侧的一些按钮词条 */
export const headerBarTips = defineMessages({
  help_center: {
    id: `${scope}.help_center`,
    defaultMessage: '帮助中心',
  },
  lang_change: {
    id: `${scope}.lang_change`,
    defaultMessage: '切换语言',
  },
  view_announcements: {
    id: `${scope}.view_announcements`,
    defaultMessage: '查看公告',
  },
  desensitize_switch: {
    id: `${scope}.desensitize_switch`,
    defaultMessage: '脱敏切换（关闭脱敏将展示客户信息，该操作为敏感操作，将被系统后台记录，如有泄露将被追究法律责任）',
  },
  wpm: {
    id: `${scope}.wpm`,
    defaultMessage: '丰向标',
  },
});
