/*
 * UAT环境权限 - 主要有PAGE和FUNC两种权限, 在authmap中取到undefined，则默认显示权限
 * PAGE一般用于页面，比如路由配置中会用到
 * FUNC一般用于功能权限，比如页面上的按钮，增删改查等
 */

const authMap = {
  AUTH_PAGE_sample: 999, // 开发占用
  AUTH_FUNC_sample: 999,

  // 货主管理
  AUTH_PAGE_companyManage_NOSECRET: 1145,
  AUTH_PAGE_companyManage_SECRET: 1144,
  AUTH_FUNC_companyManage_DETAIL: 1092,
  AUTH_FUNC_companyManage_CREATE: 1093,
  AUTH_FUNC_companyManage_MODIFY: 1094,
  AUTH_FUNC_companyManage_LOG: 1095,
  AUTH_FUNC_companyManage_EFFECTIVE: 1096,

  // 系统参数配置
  AUTH_PAGE_SysParamsConfig_NOSECRET: 1196,
  AUTH_FUNC_SysParamsConfig_EDIT: 1190,

  // 节点规则
  AUTH_PAGE_nodeRule_NOSECRET: 1147,
  AUTH_FUNC_nodeRule_DETAIL: 1104,
  AUTH_FUNC_nodeRule_CREATE: 1106,
  AUTH_FUNC_nodeRule_MODIFY: 1105,

  // 库存交易流水
  AUTH_PAGE_transactionFlow_NOSECRET: 1153,

  // 货主组
  AUTH_PAGE_companyGroup_NOSECRET: 1143,
  AUTH_FUNC_companyGroup_VIEW: 1035,
  AUTH_FUNC_companyGroup_ADD: 1036,
  AUTH_FUNC_companyGroup_DELETE: 1037,

  // 配送管理
  AUTH_PAGE_TransOrderManage_SECRET: 1188,
  AUTH_PAGE_TransOrderManage_NOSECRET: 1140,
  AUTH_FUNC_TransOrderManage_VIEW: 1023,
  AUTH_FUNC_TransOrderManage_DETAIL: 1024,
  AUTH_FUNC_TransOrderManage_VIEW_ROUTE: 1025,
  AUTH_PAGE_TransOrderManage_SEND: 1026,
  AUTH_PAGE_TransOrderManage_CANCEL: 1027,
  AUTH_PAGE_TransOrderManage_EDIT: 1028,
  AUTH_PAGE_TransOrderManage_EXPORT: 2351,

  // 调拨单管理
  AUTH_PAGE_DISTRIBUTE_MANAGE: 572827,
  AUTH_FUNC_DISTRIBUTE_CANCEL: 605820,

  // 权限管理
  AUTH_PAGE_FUNCTIONMANAGE: 1156,
  AUTH_PAGE_ROLEMANAGE: 1157,

  // 出库单查询
  AUTH_PAGE_OutOrderSearch_SECRET: 1130,
  AUTH_PAGE_OutOrderSearch_NOSECRET: 1133,
  AUTH_FUNC_OutOrderSearch_EXPORT: 1009,
  AUTH_FUNC_OutOrderSearch_EXPORT_only_unsense: 582700,
  AUTH_FUNC_OutOrderSearch_DETAIL: 1062,
  AUTH_FUNC_OutOrderSearch_CANCEL: 1074,
  AUTH_FUNC_OutOrderSearch_ROUTE: 1076,
  AUTH_FUNC_OutOrderSearch_PULLWMSORDER: 1123,
  AUTH_FUNC_OutOrderSearch_INVALID: 584445,
  AUTH_FUNC_OutOrderSearch_MEDICINE_FLAG: 584948,
  AUTH_FUNC_OutOrderSearch_FORECAST: 605921,

  // 出库温湿度管理
  AUTH_PAGE_OutboundTemperatureAndHumidity_NOSECRET: 575265,
  AUTH_FUNC_OutboundTemperatureAndHumidity_DELETE: 575265,
  AUTH_FUNC_OutboundTemperatureAndHumidity_IMPORT: 999,

  // 仓库管理
  AUTH_PAGE_WarehouseManage_SECRET: 1141,
  AUTH_PAGE_WarehouseManage_NOSECRET: 1142,
  AUTH_FUNC_WarehouseManage_CREATE: 1033,
  AUTH_FUNC_WarehouseManage_EDIT: 1034,
  AUTH_FUNC_WarehouseManage_NODE_VIEW: 1120,
  AUTH_FUNC_WarehouseManage_NODE_CREATE: 1121,
  AUTH_FUNC_WarehouseManage_NODE_EDIT: 1122,
  AUTH_FUNC_WarehouseManage_MAINTAIN_OPERATION: 1124,

  // 任务中心
  AUTH_PAGE_TaskCenter_NOSECRET: 1047,
  AUTH_FUNC_TaskCenter_COMPANY_CODE: 1048,

  // 规则中心-时效规则
  AUTH_PAGE_StrategyTimeRules_NOSECRET: 1150,
  AUTH_FUNC_StrategyTimeRules_VIEW: 1112,
  AUTH_FUNC_StrategyTimeRules_EDIT: 1113,
  AUTH_FUNC_StrategyTimeRules_CREATE: 1114,

  // 规则中心-极效规则
  AUTH_MENU_EffectiveRules_NOSECRET: 2158,
  AUTH_PUN_EffectiveRules_VIEW: 2158,
  AUTH_PUN_EffectiveRules_EDIT: 2159,
  AUTH_PUN_EffectiveRules_CREATE: 2160,

  // 模拟路由操作码规则
  AUTH_PAGE_RoutingOperationCodeRule_NOSECRET: 575179,
  AUTH_FUNC_RoutingOperationCodeRule_CREATE: 575176,
  AUTH_FUNC_RoutingOperationCodeRule_EDIT: 575177,
  AUTH_FUNC_RoutingOperationCodeRule_VIEW: 575178,

  // 入库管理
  AUTH_MENU_inboundManage: 1001,
  AUTH_PAGE_inboundManage_SECRET: 1136,
  AUTH_PAGE_inboundManage_NOSECRET: 1137,
  AUTH_FUNC_inboundManage_EXPORT: 1068,
  AUTH_FUNC_inboundManage_CANCEL: 1069,
  AUTH_FUNC_inboundManage_ISSUE: 1070,
  AUTH_FUNC_inboundManage_DETAIL: 1073,
  AUTH_FUNC_inboundManage_EDIT: 1126,
  AUTH_FUNC_inboundManage_REORDER: 1127,
  AUTH_FUNC_inboundManage_IMPORT: 65521,
  AUTH_FUNC_inboundManage_INVALID: 584446,
  AUTH_FUNC_inboundManage_MEDICINE_FLAG: 584654,
  // 入库明细查询
  AUTH_PAGE_inboundDetailManage_NOSECRET: 1138,
  AUTH_FUNC_inboundDetailManage_EXPORT: 1071,
  // 入库序列号查询
  AUTH_PAGE_inboundNoManage_NOSECRET: 1139,
  AUTH_FUNC_inboundNoManage_EXPORT: 1072,

  // 分仓规则
  AUTH_PAGE_SubWarehouseRule_NOSECRET: 1148,
  AUTH_FUNC_SubWarehouseRule_VIEW: 1107,
  AUTH_FUNC_SubWarehouseRule_EDIT: 1108,

  // 管控规则
  AUTH_PAGE_AdminRuleManage_NOSECRET: 1149,
  AUTH_FUNC_AdminRuleManage_VIEW: 1109,
  AUTH_FUNC_AdminRuleManage_EDIT: 1110,
  AUTH_FUNC_AdminRuleManage_CREATE: 1111,
  AUTH_FUNC_AdminRuleManage_IMPORT: 41178,

  // 极效规则
  AUTH_PAGE_effectiveRules: 1056,

  // 出库单处理
  AUTH_PAGE_OrderManage_SECRET: 1131,
  AUTH_PAGE_OrderManage_NOSECRET: 1134,
  AUTH_FUNC_OrderManage_VIEW: 1063,
  AUTH_FUNC_OrderManage_EDIT: 1082,
  AUTH_FUNC_OrderManage_INTERCEPT: 1080,
  AUTH_FUNC_OrderManage_CANCEL: 1087,
  AUTH_FUNC_OrderManage_LOG: 1078,
  AUTH_FUNC_OrderManage_SPLITOCCUPY: 1079,
  AUTH_FUNC_OrderManage_SPLITOCCUPY_FORCE: 611043,
  AUTH_FUNC_OrderManage_RELEASEALL: 1081,
  AUTH_FUNC_OrderManage_TMS: 1083,
  AUTH_FUNC_OrderManage_WMS: 1084,
  AUTH_FUNC_OrderManage_CANCELTMS: 1086,
  AUTH_FUNC_OrderManage_CARRIER: 1085,
  AUTH_FUNC_OrderManage_EXPORT: 1010,
  AUTH_FUNC_OrderManage_EXPORT_only_unsense: 582701,
  AUTH_FUNC_OrderManage_IGNORE: 1125,
  AUTH_FUNC_OrderManage_MERGEORDER: 2239,
  AUTH_FUNC_OrderManage_DECRYPT: 2831,
  AUTH_FUNC_OrderManage_CANCELSTACK: 41161,
  AUTH_FUNC_OrderManage_IMPORT: 65521,
  AUTH_FUNC_OrderManage_PREPAY: 41154,
  AUTH_FUNC_OrderManage_medicineCheckGood: 572675,
  AUTH_FUNC_OrderManage_INVALID: 584444,
  AUTH_FUNC_OrderManage_CREATE: 583729,
  AUTH_FUNC_OrderManage_DSCCARRIEREDIT: 587982,
  AUTH_FUNC_OrderManage_MEDICINEUNUSUALRECHECK: 596779,
  AUTH_FUNC_OrderManage_CHECKAPPLEADDRESS: 613709,
  AUTH_FUNC_OrderManage_SKUEDIT: 596960,
  AUTH_FUNC_OrderManage_TAB1000: 611010,
  AUTH_FUNC_OrderManage_TAB1100: 611011,
  AUTH_FUNC_OrderManage_TAB1200: 611012,
  AUTH_FUNC_OrderManage_TAB1500: 611013,
  AUTH_FUNC_OrderManage_TAB2000: 611014,
  AUTH_FUNC_OrderManage_TAB3000: 611015,
  AUTH_FUNC_OrderManage_TAB30001: 611016,
  AUTH_FUNC_OrderManage_TAB30002: 611017,
  AUTH_FUNC_OrderManage_TAB4000: 611018,

  // 商品管理模块
  AUTH_PAGE_GoodsManagement_NOSECRET: 1146,
  AUTH_FUNC_GoodsManagement_VIEW: 1097,
  AUTH_FUNC_GoodsManagement_CREATE: 1098,
  AUTH_FUNC_GoodsManagement_EDIT: 1099,
  AUTH_FUNC_GoodsManagement_PUSHORDER: 1100,
  AUTH_FUNC_GoodsManagement_EXPORT: 1115,

  // basicCodeConfig
  AUTH_PAGE_BasicCodeConfig_NOSECRET: 1154,
  AUTH_FUNC_BasicCodeConfig_EXPORT: 1118,
  AUTH_FUNC_BasicCodeConfig_EDIT: 1119,

  // 货主仓库库存管理
  AUTH_PAGE_CompanyWarehouseStockManage_NOSECRET: 1151,
  AUTH_FUNC_CompanyWarehouseStockManage_IMPORT: 2501,

  // 货主仓库管理
  AUTH_PAGE_CompanyStockManage_NOSECRET: 1152,

  // BOM管理
  AUTH_PAGE_BomManage_NOSECRET: 2245,
  AUTH_FUNC_BomManage_CREATE: 2244,
  AUTH_FUNC_BomManage_PUSH: 2424,
  AUTH_FUNC_BomManage_EDIT: 603893,
  AUTH_FUNC_BomManage_EFFECT: 603892,

  // 供应商管理
  AUTH_PAGE_SupplierManage_NOSECRET: 2379,
  AUTH_PAGE_SupplierManage: 2372,
  AUTH_FUNC_SupplierManage_IMPORT: 2373,
  AUTH_FUNC_SupplierManage_PUSH: 2374,
  AUTH_FUNC_SupplierManage_EXPORT: 2375,
  AUTH_FUNC_SupplierManage_DETAIL: 2376,
  AUTH_FUNC_SupplierManage_LOG: 2377,
  AUTH_FUNC_SupplierManage_EDIT: 2378,

  // 库存中心内部对账差异
  AUTH_PAGE_StockInnerDiffBill: 1202,

  // 库存快照
  AUTH_PAGE_StockPicture: 1199,

  // 缺货信息管理
  AUTH_PAGE_LackSku_SECRET: 1132,
  AUTH_PAGE_LackSku_NOSECRET: 1135,
  AUTH_FUNC_LackSku_EXPORT: 1129,

  // 库存对账
  AUTH_PAGE_InventoryReconciliation: 1197,
  AUTH_FUNC_InventoryReconciliation_DETAIL: 1164,

  // 库存版本明细
  AUTH_PAGE_InventoryVersionDetail: 1198,
  AUTH_FUNC_InventoryVersionDetail_STATEMENT: 1200,
  AUTH_FUNC_InventoryVersionDetail_EXPORT: 1201,

  // 公告管理
  AUTH_MENU_AnnounceManage: 2347,
  AUTH_PAGE_AnnounceManage: 2348,
  AUTH_FUNC_AnnounceManageUpdate: 2349,
  AUTH_FUNC_AnnounceManageView: 2350,

  // 丰向标入口
  AUTH_PAGE_FENGXIANGBIAO: 576997,

  // 拆单规则
  AUTH_PAGE_SplitOrderRule_NOSECRET: 2369,
  AUTH_FUNC_SplitOrderRule_EDIT: 2370,

  // 拆单管理
  AUTH_PAGE_OrderSplit: 1195,

  // 合单管理
  AUTH_PAGE_OrderMerge_NOSECRET: 2238,
  AUTH_FUNC_OrderMerge_EXPORT: 568604,

  // 库存推送规则
  AUTH_PAGE_InvBatchRule_NOSECRET: 2621,
  AUTH_FUNC_InvBatchRule_DETAIL: 2620,
  AUTH_FUNC_InvBatchRule_EDIT: 2619,
  AUTH_FUNC_InvBatchRule_CREATE: 2618,

  // 接口模板配置
  AUTH_PAGE_InterfaceModuleConfig_NOSECRET: 2500,
  AUTH_FUNC_InterfaceModuleConfig_EDIT: 2497,
  AUTH_FUNC_InterfaceModuleConfig_VIEW: 2499,
  AUTH_FUNC_InterfaceModuleConfig_CREATE: 2495,
  AUTH_FUNC_InterfaceModuleConfig_EFFECT: 2498,

  // 异常报文监控
  AUTH_PAGE_ExceptMessage_NOSECRET: 2711,
  AUTH_FUNC_ExceptMessage_VIEW: 2712,
  AUTH_FUNC_ExceptMessage_EDIT: 2713,
  AUTH_FUNC_ExceptMessage_PUSH: 2714,
  AUTH_FUNC_ExceptMessage_LOG: 2715,

  // 商品审核
  AUTH_PAGE_SkuAudit_NOSECRET: 2922,
  AUTH_FUNC_SkuAudit_AUDIT: 2923,

  // WMS地址配置
  AUTH_PAGE_WmsAddressConfig_NOSECRET: 41123,

  // WMS对接配置
  AUTH_PAGE_WmsDockingConfig_NOSECRET: 41124,

  // 库存中心盘点审核
  AUTH_PAGE_InventoryAudit_NOSECRET: 3560,
  AUTH_FUNC_InventoryAudit_EXPORT: 3561,
  AUTH_FUNC_InventoryAudit_UPLOAD: 3051,
  AUTH_FUNC_InventoryAudit_CANCELUPLOAD: 3052,

  // 规则中心：拦截调用规则
  AUTH_PAGE_InterceptRule_NOSECRET: 41157,
  AUTH_FUNC_InterceptRule_VIEW: 41160,
  AUTH_FUNC_InterceptRule_EDIT: 41159,
  AUTH_FUNC_InterceptRule_CREATE: 41158,

  // 导入模板配置
  AUTH_PAGE_ImportModuleConfig_NOSECRET: 65517,
  AUTH_FUNC_ImportModuleConfig_VIEW: 65518,
  AUTH_FUNC_ImportModuleConfig_EDIT: 65519,

  // 预付款规则
  AUTH_PAGE_PrePayRule_NOSECRET: 41146,
  AUTH_FUNC_PrePayRule_DETAIL: 41147,
  AUTH_FUNC_PrePayRule_EDIT: 41148,
  AUTH_FUNC_PrePayRule_CREATE: 41149,

  // 国际进口件
  AUTH_PAGE_ImportItem_NOSECRET: 65791,
  AUTH_FUNC_ImportItem_EXPORT: 65792,
  AUTH_FUNC_ImportItem_LOG: 65793,

  // 关务品名维护
  AUTH_PAGE_CustomsNameMaintenance_NOSECRET: 66293,
  AUTH_FUNC_CustomsNameMaintenance_EDIT: 66294,

  // 集运单管理
  AUTH_PAGE_CollectOrderPage_NOSECRET: 66416,
  AUTH_FUNC_CollectOrderPage_DETAIL: 66417,

  // 自提网点信息维护
  AUTH_PAGE_SelfServiceStation_NOSECRET: 66288,
  AUTH_FUNC_SelfServiceStation_CREATE: 66289,
  AUTH_FUNC_SelfServiceStation_EDIT: 66290,
  AUTH_FUNC_SelfServiceStation_IMPORT: 66291,
  AUTH_FUNC_SelfServiceStation_EXPORT: 66292,

  // 集运管理
  AUTH_PAGE_CollectTransCenter_NOSECRET: 66287,

  // 无主件识别处理
  AUTH_PAGE_UnknownManage_NOSECRET: 66967,
  AUTH_FUNC_UnknownManage_IMPORT: 66965,
  AUTH_FUNC_UnknownManage_EXPORT: 66966,

  AUTH_FUNC_WmsAddressConfig_ENCRYPT: 75615,

  // 通用的日志权限码
  AUTH_FUNC_LOG: 2377,

  // 郊区件
  AUTH_PAGE_SuburbanPartsManagement_NOSECRET: 563352,
  AUTH_FUNC_SuburbanPartsManagement_EXPORT: 563357,
  AUTH_FUNC_SuburbanPartsManagement_CREATE: 563353,
  AUTH_FUNC_SuburbanPartsManagement_EDIT: 563354,
  AUTH_FUNC_SuburbanPartsManagement_DELETE: 563356,
  // 调拨关联明细录入
  AUTH_PAGE_AllocationDetail_NOSECRET: 562226,
  AUTH_FUNC_AllocationDetail_IMPORT: 562229,
  AUTH_FUNC_AllocationDetail_EDIT: 562227,
  AUTH_FUNC_AllocationDetail_DELETE: 562228,

  // 订单对应出库时间
  AUTH_PAGE_OrderCorrespondDeliveryTime_NOSECRET: 563337,
  AUTH_FUNC_OrderCorrespondDeliveryTime_IMPORT: 563338,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EDIT: 563339,
  AUTH_FUNC_OrderCorrespondDeliveryTime_CREATE: 563340,
  AUTH_FUNC_OrderCorrespondDeliveryTime_DELETE: 563341,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EXPORT: 563342,

  // 特殊时效发送配置
  AUTH_PAGE_SpecialEffectiveSendConf_NOSECRET: 563346,
  AUTH_FUNC_SpecialEffectiveSendConf_IMPORT: 563347,
  AUTH_FUNC_SpecialEffectiveSendConf_EDIT: 563348,
  AUTH_FUNC_SpecialEffectiveSendConf_CREATE: 563349,
  AUTH_FUNC_SpecialEffectiveSendConf_DELETE: 563350,
  AUTH_FUNC_SpecialEffectiveSendConf_EXPORT: 563351,

  // 月台预约单查询
  AUTH_PAGE_PlatformManage: 563468,
  AUTH_FUNC_PlatformManage_APPROVAL: 563469,
  AUTH_FUNC_PlatformManage_SIGNIN: 563470,
  AUTH_FUNC_PlatformManage_LOADINGBEGIN: 563471,
  AUTH_FUNC_PlatformManage_FINISH: 563472,
  AUTH_FUNC_PlatformManage_CANCEL: 563473,
  AUTH_FUNC_PlatformManage_EDIT: 563533,
  AUTH_FUNC_PlatformManage_EXPORT: 563592,

  // 仓库绑定月台
  AUTH_FUNC_BindPlatform_LIST: 563524,
  AUTH_FUNC_BindPlatform_CREATE: 563526,
  AUTH_FUNC_BindPlatform_EDIT: 563527,
  AUTH_FUNC_BindPlatform_DETAIL: 563525,

  // 仓库绑定月台预约时间
  AUTH_FUNC_PlatformPeriod_LIST: 563528,
  AUTH_FUNC_PlatformPeriod_CREATE: 563530,
  AUTH_FUNC_PlatformPeriod_EDIT: 563531,
  AUTH_FUNC_PlatformPeriod_DETAIL: 563529,
  AUTH_FUNC_PlatformPeriod_DEL: 563532,

  // 通缉单查询
  AUTH_PAGE_OrderWanted_NOSECRET: 575268,
  AUTH_FUNC_OrderWanted_WANTED: 575269,
  AUTH_FUNC_OrderWanted_EXPORT: 575270,

  // 进销存管理
  AUTH_PAGE_PurchaseSaleManage_NOSECRET: 577123,
  AUTH_FUNC_PurchaseSaleManage_EXPORT: 577125,

  // 加工单管理
  AUTH_PAGE_ProcessManage_NOSECRET: 590175,
  AUTH_FUNC_ProcessManage_RECHECK: 590180,
  AUTH_FUNC_ProcessManage_PUSH: 590181,
  AUTH_FUNC_ProcessManage_CANCEL: 590182,
  AUTH_FUNC_ProcessManage_EXPORT: 590183,

  // 加工单详情管理
  AUTH_PAGE_ProcessDetailManage_MATERIAL: 590176,
  AUTH_PAGE_ProcessDetailManage_SKU: 590177,
  AUTH_PAGE_ProcessDetailManage_MATERIALCOMP: 590178,
  AUTH_PAGE_ProcessDetailManage_SKUCOMP: 590179,

  // 订单日志详情查看
  AUTH_FUNC_OrderLogDetail_VIEW: 999,

  // 门店管理
  AUTH_PAGE_StoreManage_NOSECRET: 596780,
  AUTH_FUNC_StoreManage_DETAIL: 596781,
  AUTH_FUNC_StoreManage_SUG: 596782,
  AUTH_FUNC_StoreManage_EDIT: 596783,
  AUTH_PAGE_StoreManage_PUSH: 596784,

  // PO采购订单管理
  AUTH_PAGE_PurchaseManagement: 605065,
  AUTH_PAGE_PurchaseManagementDetail: 605066,
  AUTH_FUNC_PurchaseManagement_EXPORT: 605643,
  AUTH_FUNC_PurchaseManagement_CLOSE: 999,

  // 动态称
  AUTH_PAGE_DynamicScale_NOSECRET: 606003,
  AUTH_FUNC_DynamicScale_CREATE: 606004,
  AUTH_FUNC_DynamicScale_DELETE: 606005,

  // 规则绑定详情
  AUTH_PAGE_bindRulesDetail: 999,
  AUTH_FUNC_bindRulesDetail_EXPORT: 999,

  // KA客户打标
  AUTH_PAGE_KACustomerMarking_NOSECRET: 613715,
  AUTH_FUNC_KACustomerMarking_CREATE: 613717,
  AUTH_FUNC_KACustomerMarking_EDIT: 613718,
  AUTH_FUNC_KACustomerMarking_ENABLE: 613719,

  // 订单处理顺序规则
  AUTH_PAGE_OrderSortRule_NOSECRET: 999,
};

export default authMap;
