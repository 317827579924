/* eslint-disable import/no-webpack-loader-syntax */
/*
 * @Author: lf
 * @Date: 2020-06-21 12:23:57
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-14 14:16:43
 * @Description: 入口文件
 */
import { ConnectedRouter } from 'connected-react-router';
// polyfill
import 'core-js/actual';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import history from 'utils/history';

// A CSS library that provides consistent, cross-browser default styling of HTML elements alongside useful defaults.
import 'sanitize.css/sanitize.css';

// Import Language Provider
// import LanguageProvider from 'containers/LanguageProvider';
import { initIntl } from 'i18n/i18n';
import IntlProvider from 'i18n/IntlProvider';

// Check Version
import BuildVersion from 'utils/checkBuildVersion';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./static/images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import { configureAppStore } from 'store/configureStore';

// Import Longan
// import loadLongan from 'utils/loadLongan';

// Import sensor & apm
import 'utils/stats';
// import initSensorsData, { sensorsTrackJavaScriptError } from 'utils/sensorsData';

// Import root app
import App from './pages/Layout';

// Import i18n messages
// import { translationMessages } from './i18n';

// Import root app
// import App from './AppRouter';

// Import global style
import './styles/index.less';
// 菜单icon
import './static/files/font_2886751_ahjxn8rdcrr.js';

// loadLongan();

if (process.env.NODE_ENV === 'production') {
  // 版本检查
  const buildVersion = BuildVersion.getInstance(process.env.BUILD_VERSION as any);
  buildVersion.init();
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// 由于部分用户的内网限制，无法访问google font，所以把字体去掉了，这里也同样去掉
// const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// 由于部分用户的内网限制，无法访问google font，所以把字体去掉了，这里也同样去掉
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

// Create redux store with history
const store = configureAppStore();
const MOUNT_NODE = document.getElementById('app');
// const intl = getGlobalIntl();

const render = (): void => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <IntlProvider>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </IntlProvider>
        {/* <LanguageProvider messages={messages}> */}
        {/* </LanguageProvider> */}
      </AppContainer>
    </Provider>,
    MOUNT_NODE,
  );
};

// eslint-disable-next-line
if ((module as any).hot) {
  // 注意需要依赖@types/webpack-env这个包
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  // eslint-disable-next-line
  (module as any).hot.accept(['i18n/i18n.ts', 'pages/Layout'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    initIntl().then(() => {
      render();
    });
  });
}
initIntl().then(() => {
  render();
});

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
