/*
 * @Author: your name
 * @Date: 2021-02-19 14:03:39
 * @LastEditTime: 2021-03-24 17:59:38
 * @LastEditors: 董方旭
 * @Description: In User Settings Edit
 * @FilePath: /janus/src/pages/Layout/index.tsx
 */
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { WaterMark } from '@ant-design/pro-layout';
import { Layout as AntdLayout } from 'antd';
import CoreRoute, { renderNoSidebarRoute } from 'components/Router';
import menuConfig, { menusNoSidebar } from 'configs/router.conf';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { actions } from 'store/globalSlice';
import { selectCurrentLanguage, selectLayout, selectLoginUserFunctionAuth, selectLoginUserInfo } from 'store/selectors';
import { menuMessages } from 'utils/messages';
import { getParentUrl, getQueryString, isInFrame } from 'utils/utils';
import Logo from './components/Logo';
import Menu from './components/Menu';
import { LayoutWrapper } from './components/Wrappers';
import { getMenuLangData } from './menuHelper';
import HeaderBar from './modules/HeaderBar';

const { Content, Sider } = AntdLayout;
const { useEffect, useState } = React;

// eslint-disable-next-line no-underscore-dangle
const isOnline = window._ENV.PROJECT_ENV === 'online';

function Layout() {
  const intl = useIntl();
  const [menuData, setMenuData] = useState([]);
  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);
  const lang = useSelector(selectCurrentLanguage);
  const authList = useSelector(selectLoginUserFunctionAuth);

  useEffect(() => {
    // 做一下dsc、wpm嵌入的处理，不展示首页
    if (isInFrame() && menuConfig[0] && menuConfig[0]?.path === 'homePage') {
      menuConfig.shift();
    }
    const data = getMenuLangData(intl, lang, menuConfig, menuMessages, authList);
    setMenuData(data);
  }, [JSON.stringify(menuConfig), JSON.stringify(authList)]);
  const userInfo = useSelector(selectLoginUserInfo);

  let parentUrl = getParentUrl() || ('' as string);
  console.log('父级域名', parentUrl);
  if (typeof parentUrl !== 'string') {
    parentUrl = '';
  }
  const isInWpm =
    parentUrl.includes('wpm-fusion-web.sit.sf-express.com') ||
    parentUrl.includes('elog-wpm-nweb.sit.sf-express.com') ||
    parentUrl.includes('scc-wpm.sf-express.com') ||
    // 下面是冷镜的域名，兼容一下
    parentUrl.includes('lybi.sit.sf-express.com') ||
    parentUrl.includes('lybi.sf-express.com');

  // 全局数据初始化
  useEffect(() => {
    // 无论是内嵌dsc还是wpm，都需要对cookie做处理
    if (isInFrame()) {
      const cert = getQueryString('cert');
      const castoken = getQueryString('castoken');
      console.log('cert and castoekn', cert, castoken);
      cert &&
        (document.cookie = `cert=${cert || ''};domain=${
          isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
        };path=/;secure;SameSite=None;`);
      castoken &&
        (document.cookie = `castoken=${castoken || ''};domain=${
          isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
        };path=/;secure;SameSite=None;`);
      dispatch(actions.updateLayoutCollapsed(true));
    } else {
      // 默认
      document.cookie = `cert=passport;domain=${
        isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
      };path=/;secure;SameSite=None;`;
    }
    dispatch(actions.initGlobalData());
  }, []);

  const handleCollapse = (collapse: boolean) => {
    dispatch(actions.updateLayoutCollapsed(collapse));
  };

  return (
    <WaterMark content={userInfo.sfucode} zIndex={99999} gapX={100} gapY={100} fontColor="rgba(0,0,0,0.05)">
      <Switch>
        {renderNoSidebarRoute(menusNoSidebar)}
        <LayoutWrapper>
          {/* 嵌入wpm需要将左侧菜单给隐藏掉 */}
          {isInWpm && isInFrame() ? null : (
            <Sider
              className="custom-sider"
              theme="light"
              collapsible
              width="200px"
              collapsedWidth="48px"
              collapsed={layout.collapsed}
              onCollapse={handleCollapse}
              trigger={layout.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            >
              {isInWpm && isInFrame() ? null : <Logo collapsed={layout.collapsed} />}
              <Menu />
            </Sider>
          )}
          <AntdLayout>
            <HeaderBar />
            <Content style={{ flex: 1 }}>
              <div style={{ height: '100%' }}>
                <CoreRoute menuData={menuData} sfucode={userInfo.sfucode} />
              </div>
            </Content>
          </AntdLayout>
        </LayoutWrapper>
      </Switch>
    </WaterMark>
  );
}

export default hot(Layout);
