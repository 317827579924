/*
 * @Author: 董方旭
 * @Date: 2021-02-23 14:31:53
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-03-05 11:24:44
 * @Description: ErrorBoundary
 */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import { longanDispatchError } from 'longan-sdk';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import globalMessages from 'utils/messages';

const ErrorBox = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error_icon {
    font-size: 100px;
    margin-bottom: 20px;
  }
  .refresh_btn {
    margin-left: 10px;
    vertical-align: text-top;
  }
`;

export interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<object, IState> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: object | Readonly<object>) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public componentDidCatch(error: any, info: any) {
    // 错误上报 longan自动收集
    console.log(error, info);
    // try {
    //   longanDispatchError({
    //     error_message: error.toString(),
    //     error_content: error.stack,
    //     error_level: '0',
    //     error_tag: 'error_boundary',
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  }

  static handleClick() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorBox>
          <ExclamationCircleOutlined className="error_icon" type="exclamation-circle" />
          <h1>
            <FormattedMessage {...globalMessages.systemError} />
          </h1>
          <h1>
            <FormattedMessage {...globalMessages.uTry} />
            <Button className="refresh_btn" type="primary" onClick={ErrorBoundary.handleClick}>
              <FormattedMessage {...globalMessages.refresh} />
            </Button>
          </h1>
          <h1>
            <FormattedMessage {...globalMessages.contactForSup} />
          </h1>
        </ErrorBox>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
