import React from 'react';

function IconFont({ name }: { name: string }) {
  return (
    <svg width="1em" height="1em" className="icon" fill="currentColor" stroke="currentColor" aria-hidden="true">
      <use xlinkHref={`#${name}`} />
    </svg>
  );
}

export default IconFont;
