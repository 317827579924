/*
 * 海外sit环境权限 - 主要有PAGE和FUNC两种权限, 在authmap中取到undefined，则默认显示权限
 * PAGE一般用于页面，比如路由配置中会用到
 * FUNC一般用于功能权限，比如页面上的按钮，增删改查等
 */

const authMap = {
  AUTH_PAGE_sample: 999, // 开发占用
  AUTH_FUNC_sample: 999,

  // 货主管理
  AUTH_PAGE_companyManage_NOSECRET: 1001145,
  AUTH_PAGE_companyManage_SECRET: 1001144,
  AUTH_FUNC_companyManage_DETAIL: 1001092,
  AUTH_FUNC_companyManage_CREATE: 1001093,
  AUTH_FUNC_companyManage_MODIFY: 1001094,
  AUTH_FUNC_companyManage_LOG: 1001095,
  AUTH_FUNC_companyManage_EFFECTIVE: 1001096,

  // 系统参数配置
  AUTH_PAGE_SysParamsConfig_NOSECRET: 1001196,
  AUTH_FUNC_SysParamsConfig_EDIT: 1001190,

  // 节点规则
  AUTH_PAGE_nodeRule_NOSECRET: 1001147,
  AUTH_FUNC_nodeRule_DETAIL: 1001104,
  AUTH_FUNC_nodeRule_CREATE: 1001106,
  AUTH_FUNC_nodeRule_MODIFY: 1001105,

  // 库存交易流水
  AUTH_PAGE_transactionFlow_NOSECRET: 1001153,

  // 货主组
  AUTH_PAGE_companyGroup_NOSECRET: 1001143,
  AUTH_FUNC_companyGroup_VIEW: 1001035,
  AUTH_FUNC_companyGroup_ADD: 1001036,
  AUTH_FUNC_companyGroup_DELETE: 1001037,

  // 配送管理
  AUTH_PAGE_TransOrderManage_SECRET: 1001188,
  AUTH_PAGE_TransOrderManage_NOSECRET: 1001140,
  AUTH_FUNC_TransOrderManage_VIEW: 1001023,
  AUTH_FUNC_TransOrderManage_DETAIL: 1001024,
  AUTH_FUNC_TransOrderManage_VIEW_ROUTE: 1001025,
  AUTH_PAGE_TransOrderManage_SEND: 1001026,
  AUTH_PAGE_TransOrderManage_CANCEL: 1001027,
  AUTH_PAGE_TransOrderManage_EDIT: 1001028,
  AUTH_PAGE_TransOrderManage_EXPORT: 1002351,

  // 调拨单管理
  AUTH_PAGE_DISTRIBUTE_MANAGE: 1572827,
  AUTH_FUNC_DISTRIBUTE_CANCEL: 1605820,

  // 权限管理
  AUTH_PAGE_FUNCTIONMANAGE: 1001156,
  AUTH_PAGE_ROLEMANAGE: 1001157,

  // 出库单查询
  AUTH_PAGE_OutOrderSearch_SECRET: 1001130,
  AUTH_PAGE_OutOrderSearch_NOSECRET: 1001133,
  AUTH_FUNC_OutOrderSearch_EXPORT: 1001009,
  AUTH_FUNC_OutOrderSearch_EXPORT_only_unsense: 1582700,
  AUTH_FUNC_OutOrderSearch_DETAIL: 1001062,
  AUTH_FUNC_OutOrderSearch_CANCEL: 1001074,
  AUTH_FUNC_OutOrderSearch_ROUTE: 1001076,
  AUTH_FUNC_OutOrderSearch_PULLWMSORDER: 1001123,
  AUTH_FUNC_OutOrderSearch_INVALID: 1584445,
  AUTH_FUNC_OutOrderSearch_MEDICINE_FLAG: 1584948,

  // 出库温湿度管理
  AUTH_PAGE_OutboundTemperatureAndHumidity_NOSECRET: 1575265,
  AUTH_FUNC_OutboundTemperatureAndHumidity_DELETE: 1575265,
  AUTH_FUNC_OutboundTemperatureAndHumidity_IMPORT: 999,

  // 仓库管理
  AUTH_PAGE_WarehouseManage_SECRET: 1001141,
  AUTH_PAGE_WarehouseManage_NOSECRET: 1001142,
  AUTH_FUNC_WarehouseManage_CREATE: 1001033,
  AUTH_FUNC_WarehouseManage_EDIT: 1001034,
  AUTH_FUNC_WarehouseManage_NODE_VIEW: 1001120,
  AUTH_FUNC_WarehouseManage_NODE_CREATE: 1001121,
  AUTH_FUNC_WarehouseManage_NODE_EDIT: 1001122,
  AUTH_FUNC_WarehouseManage_MAINTAIN_OPERATION: 1001124,

  // 任务中心
  AUTH_PAGE_TaskCenter_NOSECRET: 1001047,
  AUTH_FUNC_TaskCenter_COMPANY_CODE: 1001048,

  // 规则中心-时效规则
  AUTH_PAGE_StrategyTimeRules_NOSECRET: 1001150,
  AUTH_FUNC_StrategyTimeRules_VIEW: 1001112,
  AUTH_FUNC_StrategyTimeRules_EDIT: 1001113,
  AUTH_FUNC_StrategyTimeRules_CREATE: 1001114,

  // 规则中心-极效规则
  AUTH_MENU_EffectiveRules_NOSECRET: 1002158,
  AUTH_PUN_EffectiveRules_VIEW: 1002158,
  AUTH_PUN_EffectiveRules_EDIT: 1002159,
  AUTH_PUN_EffectiveRules_CREATE: 1002160,

  // 模拟路由操作码规则
  AUTH_PAGE_RoutingOperationCodeRule_NOSECRET: 1575179,
  AUTH_FUNC_RoutingOperationCodeRule_CREATE: 1575176,
  AUTH_FUNC_RoutingOperationCodeRule_EDIT: 1575177,
  AUTH_FUNC_RoutingOperationCodeRule_VIEW: 1575178,

  // 入库管理
  AUTH_MENU_inboundManage: 1001001,
  AUTH_PAGE_inboundManage_SECRET: 1001136,
  AUTH_PAGE_inboundManage_NOSECRET: 1001137,
  AUTH_FUNC_inboundManage_EXPORT: 1001068,
  AUTH_FUNC_inboundManage_CANCEL: 1001069,
  AUTH_FUNC_inboundManage_ISSUE: 1001070,
  AUTH_FUNC_inboundManage_DETAIL: 1001073,
  AUTH_FUNC_inboundManage_EDIT: 1001126,
  AUTH_FUNC_inboundManage_REORDER: 1001127,
  AUTH_FUNC_inboundManage_IMPORT: 165521,
  AUTH_FUNC_inboundManage_INVALID: 1584446,
  AUTH_FUNC_inboundManage_MEDICINE_FLAG: 1584654,
  // 入库明细查询
  AUTH_PAGE_inboundDetailManage_NOSECRET: 1001138,
  AUTH_FUNC_inboundDetailManage_EXPORT: 1001071,
  // 入库序列号查询
  AUTH_PAGE_inboundNoManage_NOSECRET: 1001139,
  AUTH_FUNC_inboundNoManage_EXPORT: 1001072,

  // 分仓规则
  AUTH_PAGE_SubWarehouseRule_NOSECRET: 1001148,
  AUTH_FUNC_SubWarehouseRule_VIEW: 1001107,
  AUTH_FUNC_SubWarehouseRule_EDIT: 1001108,

  // 管控规则
  AUTH_PAGE_AdminRuleManage_NOSECRET: 1001149,
  AUTH_FUNC_AdminRuleManage_VIEW: 1001109,
  AUTH_FUNC_AdminRuleManage_EDIT: 1001110,
  AUTH_FUNC_AdminRuleManage_CREATE: 1001111,
  AUTH_FUNC_AdminRuleManage_IMPORT: 1041178,

  // 极效规则
  AUTH_PAGE_effectiveRules: 1001056,

  // 出库单处理
  AUTH_PAGE_OrderManage_SECRET: 1001131,
  AUTH_PAGE_OrderManage_NOSECRET: 1001134,
  AUTH_FUNC_OrderManage_VIEW: 1001063,
  AUTH_FUNC_OrderManage_EDIT: 1001082,
  AUTH_FUNC_OrderManage_INTERCEPT: 1001080,
  AUTH_FUNC_OrderManage_CANCEL: 1001087,
  AUTH_FUNC_OrderManage_LOG: 1001078,
  AUTH_FUNC_OrderManage_SPLITOCCUPY: 1001079,
  AUTH_FUNC_OrderManage_SPLITOCCUPY_FORCE: 1611043,
  AUTH_FUNC_OrderManage_RELEASEALL: 1001081,
  AUTH_FUNC_OrderManage_TMS: 1001083,
  AUTH_FUNC_OrderManage_WMS: 1001084,
  AUTH_FUNC_OrderManage_CANCELTMS: 1001086,
  AUTH_FUNC_OrderManage_CARRIER: 1001085,
  AUTH_FUNC_OrderManage_EXPORT: 1001010,
  AUTH_FUNC_OrderManage_EXPORT_only_unsense: 1582701,
  AUTH_FUNC_OrderManage_IGNORE: 1001125,
  AUTH_FUNC_OrderManage_MERGEORDER: 1002239,
  AUTH_FUNC_OrderManage_CANCELSTACK: 1041161,
  AUTH_FUNC_OrderManage_IMPORT: 165521,
  AUTH_FUNC_OrderManage_PREPAY: 1041154,
  AUTH_FUNC_OrderManage_medicineCheckGood: 1572675,
  AUTH_FUNC_OrderManage_INVALID: 1584444,
  AUTH_FUNC_OrderManage_CREATE: 1583729,
  AUTH_FUNC_OrderManage_DSCCARRIEREDIT: 1587982,
  AUTH_FUNC_OrderManage_MEDICINEUNUSUALRECHECK: 1596779,
  AUTH_FUNC_OrderManage_CHECKAPPLEADDRESS: 1613709,
  AUTH_FUNC_OrderManage_SKUEDIT: 1596960,
  AUTH_FUNC_OrderManage_TAB1000: 1611010,
  AUTH_FUNC_OrderManage_TAB1100: 1611011,
  AUTH_FUNC_OrderManage_TAB1200: 1611012,
  AUTH_FUNC_OrderManage_TAB1500: 1611013,
  AUTH_FUNC_OrderManage_TAB2000: 1611014,
  AUTH_FUNC_OrderManage_TAB3000: 1611015,
  AUTH_FUNC_OrderManage_TAB30001: 1611016,
  AUTH_FUNC_OrderManage_TAB30002: 1611017,
  AUTH_FUNC_OrderManage_TAB4000: 1611018,

  // 商品管理模块
  AUTH_PAGE_GoodsManagement_NOSECRET: 1001146,
  AUTH_FUNC_GoodsManagement_VIEW: 1001097,
  AUTH_FUNC_GoodsManagement_CREATE: 1001098,
  AUTH_FUNC_GoodsManagement_EDIT: 1001099,
  AUTH_FUNC_GoodsManagement_PUSHORDER: 1001100,
  AUTH_FUNC_GoodsManagement_EXPORT: 1001115,

  // basicCodeConfig
  AUTH_PAGE_BasicCodeConfig_NOSECRET: 1001154,
  AUTH_FUNC_BasicCodeConfig_EXPORT: 1001118,
  AUTH_FUNC_BasicCodeConfig_EDIT: 1001119,

  // 货主仓库库存管理
  AUTH_PAGE_CompanyWarehouseStockManage_NOSECRET: 1001151,
  AUTH_FUNC_CompanyWarehouseStockManage_IMPORT: 1002501,

  // 货主仓库管理
  AUTH_PAGE_CompanyStockManage_NOSECRET: 1001152,

  // BOM管理
  AUTH_PAGE_BomManage_NOSECRET: 1002245,
  AUTH_FUNC_BomManage_CREATE: 1002244,
  AUTH_FUNC_BomManage_PUSH: 1002424,
  AUTH_FUNC_BomManage_EDIT: 1603893,
  AUTH_FUNC_BomManage_EFFECT: 1603892,

  // 供应商管理
  AUTH_PAGE_SupplierManage_NOSECRET: 1002379,
  AUTH_PAGE_SupplierManage: 1002372,
  AUTH_FUNC_SupplierManage_IMPORT: 1002373,
  AUTH_FUNC_SupplierManage_PUSH: 1002374,
  AUTH_FUNC_SupplierManage_EXPORT: 1002375,
  AUTH_FUNC_SupplierManage_DETAIL: 1002376,
  AUTH_FUNC_SupplierManage_LOG: 1002377,
  AUTH_FUNC_SupplierManage_EDIT: 1002378,

  // 库存中心内部对账差异
  AUTH_PAGE_StockInnerDiffBill: 1001202,

  // 库存快照
  AUTH_PAGE_StockPicture: 1001199,

  // 缺货信息管理
  AUTH_PAGE_LackSku_SECRET: 1001132,
  AUTH_PAGE_LackSku_NOSECRET: 1001135,
  AUTH_FUNC_LackSku_EXPORT: 1001129,

  // 库存对账
  AUTH_PAGE_InventoryReconciliation: 1001197,
  AUTH_FUNC_InventoryReconciliation_DETAIL: 1001164,

  // 库存版本明细
  AUTH_PAGE_InventoryVersionDetail: 1001198,
  AUTH_FUNC_InventoryVersionDetail_STATEMENT: 1001200,
  AUTH_FUNC_InventoryVersionDetail_EXPORT: 1001201,

  // 公告管理
  AUTH_MENU_AnnounceManage: 1002347,
  AUTH_PAGE_AnnounceManage: 1002348,
  AUTH_FUNC_AnnounceManageUpdate: 1002349,
  AUTH_FUNC_AnnounceManageView: 1002350,

  // 丰向标入口
  AUTH_PAGE_FENGXIANGBIAO: 1576997,

  // 拆单规则
  AUTH_PAGE_SplitOrderRule_NOSECRET: 1002369,
  AUTH_FUNC_SplitOrderRule_EDIT: 1002370,

  // 拆单管理
  AUTH_PAGE_OrderSplit: 1001195,

  // 合单管理
  AUTH_PAGE_OrderMerge_NOSECRET: 1002238,
  AUTH_FUNC_OrderMerge_EXPORT: 1568604,

  // 库存推送规则
  AUTH_PAGE_InvBatchRule_NOSECRET: 1002621,
  AUTH_FUNC_InvBatchRule_DETAIL: 1002620,
  AUTH_FUNC_InvBatchRule_EDIT: 1002619,
  AUTH_FUNC_InvBatchRule_CREATE: 1002618,

  // 接口模板配置
  AUTH_PAGE_InterfaceModuleConfig_NOSECRET: 1002500,
  AUTH_FUNC_InterfaceModuleConfig_EDIT: 1002497,
  AUTH_FUNC_InterfaceModuleConfig_VIEW: 1002499,
  AUTH_FUNC_InterfaceModuleConfig_CREATE: 1002495,
  AUTH_FUNC_InterfaceModuleConfig_EFFECT: 1002498,

  // 异常报文监控
  AUTH_PAGE_ExceptMessage_NOSECRET: 1002711,
  AUTH_FUNC_ExceptMessage_VIEW: 1002712,
  AUTH_FUNC_ExceptMessage_EDIT: 1002713,
  AUTH_FUNC_ExceptMessage_PUSH: 1002714,
  AUTH_FUNC_ExceptMessage_LOG: 1002715,

  // sku审核
  AUTH_PAGE_SkuAudit_NOSECRET: 1002922,
  AUTH_FUNC_SkuAudit_AUDIT: 1002923,

  // WMS地址配置
  AUTH_PAGE_WmsAddressConfig_NOSECRET: 1041123,

  // WMS对接配置
  AUTH_PAGE_WmsDockingConfig_NOSECRET: 1041124,

  // 库存中心盘点审核
  AUTH_PAGE_InventoryAudit_NOSECRET: 1003560,
  AUTH_FUNC_InventoryAudit_EXPORT: 1003561,
  AUTH_FUNC_InventoryAudit_UPLOAD: 1003051,
  AUTH_FUNC_InventoryAudit_CANCELUPLOAD: 1003052,

  // 规则中心：拦截调用规则
  AUTH_PAGE_InterceptRule_NOSECRET: 1041157,
  AUTH_FUNC_InterceptRule_VIEW: 1041160,
  AUTH_FUNC_InterceptRule_EDIT: 1041159,
  AUTH_FUNC_InterceptRule_CREATE: 1041158,

  // 导入模板配置
  AUTH_PAGE_ImportModuleConfig_NOSECRET: 165517,
  AUTH_FUNC_ImportModuleConfig_VIEW: 165518,
  AUTH_FUNC_ImportModuleConfig_EDIT: 165519,

  // 预付款规则
  AUTH_PAGE_PrePayRule_NOSECRET: 1041146,
  AUTH_FUNC_PrePayRule_DETAIL: 1041147,
  AUTH_FUNC_PrePayRule_EDIT: 1041148,
  AUTH_FUNC_PrePayRule_CREATE: 1041149,

  // 国际进口件
  AUTH_PAGE_ImportItem_NOSECRET: 165791,
  AUTH_FUNC_ImportItem_EXPORT: 165792,
  AUTH_FUNC_ImportItem_LOG: 165793,

  // 集运管理
  AUTH_PAGE_CustomsNameMaintenance_NOSECRET: 166293,
  AUTH_FUNC_CustomsNameMaintenance_EDIT: 166294,

  // 集运单管理
  AUTH_PAGE_CollectOrderPage_NOSECRET: 166416,
  AUTH_FUNC_CollectOrderPage_DETAIL: 166417,

  // 自提网点信息维护
  AUTH_PAGE_SelfServiceStation_NOSECRET: 166288,
  AUTH_FUNC_SelfServiceStation_CREATE: 166289,
  AUTH_FUNC_SelfServiceStation_EDIT: 166290,
  AUTH_FUNC_SelfServiceStation_IMPORT: 166291,
  AUTH_FUNC_SelfServiceStation_EXPORT: 166292,

  // 集运管理
  AUTH_PAGE_CollectTransCenter_NOSECRET: 166287,

  // 无主件识别处理
  AUTH_PAGE_UnknownManage_NOSECRET: 166967,
  AUTH_FUNC_UnknownManage_IMPORT: 166965,
  AUTH_FUNC_UnknownManage_EXPORT: 166966,

  AUTH_FUNC_WmsAddressConfig_ENCRYPT: 1075615,

  // 通用的日志权限码
  AUTH_FUNC_LOG: 1002377,

  // 月台预约单查询
  AUTH_PAGE_PlatformManage: 1563468,
  AUTH_FUNC_PlatformManage_APPROVAL: 1563469,
  AUTH_FUNC_PlatformManage_SIGNIN: 1563470,
  AUTH_FUNC_PlatformManage_LOADINGBEGIN: 1563471,
  AUTH_FUNC_PlatformManage_FINISH: 1563472,
  AUTH_FUNC_PlatformManage_CANCEL: 1563473,
  AUTH_FUNC_PlatformManage_EDIT: 1563533,
  AUTH_FUNC_PlatformManage_EXPORT: 1563592,

  // 仓库绑定月台
  AUTH_FUNC_BindPlatform_LIST: 1563524,
  AUTH_FUNC_BindPlatform_CREATE: 1563526,
  AUTH_FUNC_BindPlatform_EDIT: 1563527,
  AUTH_FUNC_BindPlatform_DETAIL: 1563525,

  // 仓库绑定月台预约时间
  AUTH_FUNC_PlatformPeriod_LIST: 1563528,
  AUTH_FUNC_PlatformPeriod_CREATE: 1563530,
  AUTH_FUNC_PlatformPeriod_EDIT: 1563531,
  AUTH_FUNC_PlatformPeriod_DETAIL: 1563529,
  AUTH_FUNC_PlatformPeriod_DEL: 1563532,

  // 订单对应出库时间
  AUTH_PAGE_OrderCorrespondDeliveryTime_NOSECRET: 1563337,
  AUTH_FUNC_OrderCorrespondDeliveryTime_IMPORT: 1563338,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EDIT: 1563339,
  AUTH_FUNC_OrderCorrespondDeliveryTime_CREATE: 1563340,
  AUTH_FUNC_OrderCorrespondDeliveryTime_DELETE: 1563341,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EXPORT: 1563342,

  // 特殊时效发送配置
  AUTH_PAGE_SpecialEffectiveSendConf_NOSECRET: 1563346,
  AUTH_FUNC_SpecialEffectiveSendConf_IMPORT: 1563347,
  AUTH_FUNC_SpecialEffectiveSendConf_EDIT: 1563348,
  AUTH_FUNC_SpecialEffectiveSendConf_CREATE: 1563349,
  AUTH_FUNC_SpecialEffectiveSendConf_DELETE: 1563350,
  AUTH_FUNC_SpecialEffectiveSendConf_EXPORT: 1563351,

  // 郊区件
  AUTH_PAGE_SuburbanPartsManagement_NOSECRET: 1563352,
  AUTH_FUNC_SuburbanPartsManagement_EXPORT: 1563357,
  AUTH_FUNC_SuburbanPartsManagement_CREATE: 1563353,
  AUTH_FUNC_SuburbanPartsManagement_EDIT: 1563354,
  AUTH_FUNC_SuburbanPartsManagement_DELETE: 1563356,

  // 调拨关联明细录入
  AUTH_PAGE_AllocationDetail_NOSECRET: 1562226,
  AUTH_FUNC_AllocationDetail_IMPORT: 1562229,
  AUTH_FUNC_AllocationDetail_EDIT: 1562227,
  AUTH_FUNC_AllocationDetail_DELETE: 1562228,

  // 通缉单查询
  AUTH_PAGE_OrderWanted_NOSECRET: 1575268,
  AUTH_FUNC_OrderWanted_WANTED: 1575269,
  AUTH_FUNC_OrderWanted_EXPORT: 1575270,

  // 进销存管理
  AUTH_PAGE_PurchaseSaleManage_NOSECRET: 1577123,
  AUTH_FUNC_PurchaseSaleManage_EXPORT: 1577125,

  // 加工单管理
  AUTH_PAGE_ProcessManage_NOSECRET: 1590175,
  AUTH_FUNC_ProcessManage_RECHECK: 1590180,
  AUTH_FUNC_ProcessManage_PUSH: 1590181,
  AUTH_FUNC_ProcessManage_CANCEL: 1590182,
  AUTH_FUNC_ProcessManage_EXPORT: 1590183,

  // 加工单详情管理
  AUTH_PAGE_ProcessDetailManage_MATERIAL: 1590176,
  AUTH_PAGE_ProcessDetailManage_SKU: 1590177,
  AUTH_PAGE_ProcessDetailManage_MATERIALCOMP: 1590178,
  AUTH_PAGE_ProcessDetailManage_SKUCOMP: 1590179,

  // 订单日志详情查看
  AUTH_FUNC_OrderLogDetail_VIEW: 999,

  // 门店管理
  AUTH_PAGE_StoreManage_NOSECRET: 1596780,
  AUTH_FUNC_StoreManage_DETAIL: 1596781,
  AUTH_FUNC_StoreManage_SUG: 1596782,
  AUTH_FUNC_StoreManage_EDIT: 1596783,
  AUTH_PAGE_StoreManage_PUSH: 1596784,

  // PO采购订单管理
  AUTH_PAGE_PurchaseManagement: 1605065,
  AUTH_PAGE_PurchaseManagementDetail: 1605066,
  AUTH_FUNC_PurchaseManagement_EXPORT: 1605643,
  AUTH_FUNC_PurchaseManagement_CLOSE: 999,

  // 动态称
  AUTH_PAGE_DynamicScale_NOSECRET: 1606003,
  AUTH_FUNC_DynamicScale_CREATE: 1606004,
  AUTH_FUNC_DynamicScale_DELETE: 1606005,

  // 规则绑定详情
  AUTH_PAGE_bindRulesDetail: 999,
  AUTH_FUNC_bindRulesDetail_EXPORT: 999,

  // KA客户打标
  AUTH_PAGE_KACustomerMarking_NOSECRET: 1613715,
  AUTH_FUNC_KACustomerMarking_CREATE: 1613717,
  AUTH_FUNC_KACustomerMarking_EDIT: 1613718,
  AUTH_FUNC_KACustomerMarking_ENABLE: 1613719,
};

export default authMap;
