import { IMenuDataItem } from 'components/Router/types';

import authMap from './auth.conf';

const config: Array<IMenuDataItem> = [
  {
    path: 'homePage',
    icon: 'icon-shouye',
    name: '首页',
    authOnlyNoSecret: authMap.AUTH_PAGE_sample,
  },
  {
    path: 'sysParamsConfig',
    icon: 'icon-jichudaimapeizhi',
    authOnlyNoSecret: authMap.AUTH_PAGE_SysParamsConfig_NOSECRET,
    name: '系统参数设置',
  },
  {
    path: 'basicCodeConfig',
    icon: 'icon-jichudaima',
    authOnlyNoSecret: authMap.AUTH_PAGE_BasicCodeConfig_NOSECRET,
    name: '基础代码',
  },
  {
    path: 'taskCenter',
    icon: 'icon-renwuzhongxin',
    name: '任务中心',
    authOnlyNoSecret: authMap.AUTH_PAGE_TaskCenter_NOSECRET,
  },
  {
    path: 'basicData',
    icon: 'icon-jichuxinxi',
    name: '基础数据',
    children: [
      {
        path: 'companyManage',
        name: '货主管理',
        authOnlySecret: authMap.AUTH_PAGE_companyManage_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_companyManage_NOSECRET,
      },
      {
        path: 'companyGroupManage',
        name: '货主组管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_companyGroup_NOSECRET,
      },
      {
        path: 'warehouseManage',
        name: '仓库管理',
        authOnlySecret: authMap.AUTH_PAGE_WarehouseManage_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_WarehouseManage_NOSECRET,
      },
      {
        path: 'goodsManagement',
        name: '商品管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_GoodsManagement_NOSECRET,
      },
      {
        path: 'bOMManage',
        name: 'BOM管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_BomManage_NOSECRET,
      },
      {
        path: 'skuAudit',
        name: '商品审核',
        authOnlyNoSecret: authMap.AUTH_PAGE_SkuAudit_NOSECRET,
      },
      {
        path: 'supplierManage',
        name: '供应商管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_SupplierManage_NOSECRET,
      },
      {
        path: 'storeManage',
        name: '门店管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_sample,
      },
      {
        path: 'suburbanPartsManagement',
        name: '郊区件管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_SuburbanPartsManagement_NOSECRET,
      },
      {
        path: 'shopManage',
        name: '店铺管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_ShopManage_NOSECRET,
      },
      {
        path: 'importModuleConfig',
        name: '导入模板配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_ImportModuleConfig_NOSECRET,
      },
      {
        path: 'interfaceModuleConfig',
        name: '接口模板配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_InterfaceModuleConfig_NOSECRET,
      },
      {
        path: 'wmsAddressConfig',
        name: 'WMS地址配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_WmsAddressConfig_NOSECRET,
      },
      {
        path: 'wmsSysCodeDockingConfig',
        name: 'WMS对接配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_WmsDockingConfig_NOSECRET,
      },
      {
        path: 'orderCorrespondDeliveryTime',
        name: '订单对应出库时间',
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderCorrespondDeliveryTime_NOSECRET,
      },
      {
        path: 'specialEffectiveSendConf',
        name: '特殊时效发送配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_SpecialEffectiveSendConf_NOSECRET,
      },
      {
        path: 'dynamicScale',
        name: '动态称误差范围',
        authOnlyNoSecret: authMap.AUTH_PAGE_DynamicScale_NOSECRET,
      },
      {
        path: 'timeRuleFlowConfig',
        name: '时效规则流向配置',
        authOnlyNoSecret: authMap.AUTH_PAGE_TimeRuleFlow_NOSECRET,
      },
      {
        path: 'kACustomerMarking',
        name: 'KA客户打标',
        authOnlyNoSecret: authMap.AUTH_PAGE_KACustomerMarking_NOSECRET,
      },
    ],
  },
  {
    path: 'onRuleCenter',
    icon: 'icon-guizezhongxin',
    name: '规则中心',
    children: [
      {
        path: 'nodeRule',
        name: '节点规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_nodeRule_NOSECRET,
      },
      {
        path: 'subWarehouseRule',
        name: '分仓规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_SubWarehouseRule_NOSECRET,
      },
      {
        path: 'adminRuleManage',
        name: '管控规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_AdminRuleManage_NOSECRET,
      },
      {
        path: 'strategyTimeRules',
        name: '时效规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_StrategyTimeRules_NOSECRET,
      },
      {
        path: 'effectiveRules',
        name: '极效规则',
        authOnlyNoSecret: authMap.AUTH_MENU_EffectiveRules_NOSECRET,
      },
      {
        path: 'splitOrderRule',
        name: '拆单规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_SplitOrderRule_NOSECRET,
      },
      {
        path: 'invBatchRule',
        name: '库存推送规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_InvBatchRule_NOSECRET,
      },
      {
        path: 'interceptRule',
        name: '拦截调用规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_InterceptRule_NOSECRET,
      },
      {
        path: 'prePayRule',
        name: '预付款规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_PrePayRule_NOSECRET,
      },
      {
        path: 'routingOperationCodeRule',
        name: '模拟路由操作规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_RoutingOperationCodeRule_NOSECRET,
      },
      {
        path: 'markingRule',
        name: '打标规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_MarkingRule_NOSECRET,
      },
      {
        path: 'bindRulesDetail',
        name: '规则绑定详情',
        authOnlyNoSecret: authMap.AUTH_PAGE_bindRulesDetail,
      },
      {
        path: 'orderSortRule',
        name: '订单处理顺序规则',
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderSortRule_NOSECRET,
      },
    ],
  },
  {
    path: 'onInboundCenter',
    icon: 'icon-rukuguanli',
    name: '入库管理',
    children: [
      {
        path: 'inboundManage',
        name: '入库单管理',
        exist: true,
        authOnlySecret: authMap.AUTH_PAGE_inboundManage_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_inboundManage_NOSECRET,
        isForcedDesensitized: true,
        children: [
          {
            hidden: true,
            isDetail: true,
            path: 'inboundDetailPage',
            name: '入库单管理详情',
            authOnlySecret: authMap.AUTH_PAGE_inboundManage_SECRET,
            authOnlyNoSecret: authMap.AUTH_PAGE_inboundManage_NOSECRET,
          },
        ],
      },
      {
        path: 'inboundDetailManage',
        name: '入库单明细查询',
        authOnlyNoSecret: authMap.AUTH_PAGE_inboundDetailManage_NOSECRET,
      },
      {
        path: 'inboundNoManage',
        name: '入库序列号查询',
        authOnlyNoSecret: authMap.AUTH_PAGE_inboundNoManage_NOSECRET,
      },
      // 月台这里个页面没用，去掉这个入口
      // {
      //   path: 'platformManage',
      //   name: '月台预约单查询',
      //   authOnlyNoSecret: authMap.AUTH_PAGE_PlatformManage,
      // },
      {
        path: 'purchaseManagement',
        name: 'PO采购订单管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_PurchaseManagement,
        exist: true,
        children: [
          {
            hidden: true,
            isDetail: true,
            path: 'purchaseManagementDetail',
            name: 'PO采购订单管理详情',
            authOnlyNoSecret: authMap.AUTH_PAGE_PurchaseManagementDetail,
          },
        ],
      },
    ],
  },
  {
    path: 'onOutboundCenter',
    icon: 'icon-chukuguanli',
    name: '出库管理',
    children: [
      {
        path: 'orderManage',
        name: '出库单处理',
        exist: true,
        authOnlySecret: authMap.AUTH_PAGE_OrderManage_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderManage_NOSECRET,
        // isForcedDesensitized: true,
        children: [
          {
            path: 'outOrderDetail',
            name: '出库单详情',
            hidden: true,
            isDetail: true,
            authOnlySecret: authMap.AUTH_PAGE_OrderManage_SECRET,
            authOnlyNoSecret: authMap.AUTH_PAGE_OrderManage_NOSECRET,
          },
        ],
      },
      {
        path: 'outOrderSearch',
        name: '出库单查询',
        exist: true,
        authOnlySecret: authMap.AUTH_PAGE_OutOrderSearch_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_OutOrderSearch_NOSECRET,
        // isForcedDesensitized: true,
        children: [
          {
            path: 'outOrderDetail',
            name: '出库单详情',
            hidden: true,
            isDetail: true,
            authOnlySecret: authMap.AUTH_PAGE_OutOrderSearch_SECRET,
            authOnlyNoSecret: authMap.AUTH_PAGE_OutOrderSearch_NOSECRET,
          },
        ],
      },
      {
        path: 'lackSku',
        name: '缺货信息查询',
        authOnlySecret: authMap.AUTH_PAGE_LackSku_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_LackSku_NOSECRET,
        isForcedDesensitized: true,
      },
      {
        path: 'orderSplit',
        name: '拆单管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderSplit,
      },
      {
        path: 'orderMerge',
        name: '合单管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderMerge_NOSECRET,
      },
      {
        path: 'importItemSearch',
        name: '国际进口件查询',
        authOnlyNoSecret: authMap.AUTH_PAGE_ImportItem_NOSECRET,
      },
      {
        path: 'allocationDetail',
        name: '调拨关联明细录入',
        authOnlyNoSecret: authMap.AUTH_PAGE_AllocationDetail_NOSECRET,
      },
      {
        path: 'orderWanted',
        name: '运单通缉查询',
        authOnlyNoSecret: authMap.AUTH_PAGE_OrderWanted_NOSECRET,
      },
      {
        path: 'outboundTemperatureAndHumidity',
        name: '出库温湿度管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_OutboundTemperatureAndHumidity_NOSECRET,
      },
    ],
  },
  {
    path: 'collectTransCenter',
    icon: 'icon-jiyunguanli',
    name: '集运管理',
    authOnlyNoSecret: authMap.AUTH_PAGE_CollectTransCenter_NOSECRET,
    children: [
      {
        path: 'collectOrderPage',
        name: '集运单管理',
        exist: true,
        authOnlyNoSecret: authMap.AUTH_PAGE_CollectOrderPage_NOSECRET,
        children: [
          {
            hidden: true,
            isDetail: true,
            path: 'collectOrderDetailPage',
            name: '集运单详情',
            authOnlyNoSecret: authMap.AUTH_FUNC_CollectOrderPage_DETAIL,
          },
        ],
      },
      {
        path: 'customsNameMaintenance',
        name: '关务品名维护',
        authOnlyNoSecret: authMap.AUTH_PAGE_CustomsNameMaintenance_NOSECRET,
      },
      {
        path: 'unknownManage',
        name: '无主件识别处理',
        authOnlyNoSecret: authMap.AUTH_PAGE_UnknownManage_NOSECRET,
      },
      {
        path: 'selfServiceStation',
        name: '自提网点维护',
        authOnlyNoSecret: authMap.AUTH_PAGE_SelfServiceStation_NOSECRET,
      },
    ],
  },
  {
    path: 'distributeManage',
    icon: 'icon-navicon-kcdb',
    name: '调拨单管理',
    authOnlyNoSecret: authMap.AUTH_PAGE_DISTRIBUTE_MANAGE,
  },
  {
    path: 'transOrderManage',
    icon: 'icon-peisongguanli',
    name: '配送管理',
    authOnlySecret: authMap.AUTH_PAGE_TransOrderManage_SECRET,
    authOnlyNoSecret: authMap.AUTH_PAGE_TransOrderManage_NOSECRET,
    exist: true,
    children: [
      {
        path: 'transOrderDetail',
        name: '配送订单详情',
        hidden: true,
        isDetail: true,
        authOnlySecret: authMap.AUTH_PAGE_TransOrderManage_SECRET,
        authOnlyNoSecret: authMap.AUTH_PAGE_TransOrderManage_NOSECRET,
      },
    ],
  },
  {
    path: 'processOrder',
    name: '加工单管理',
    icon: 'icon-jiagongdanchuli',
    authOnlyNoSecret: authMap.AUTH_PAGE_ProcessManage_NOSECRET,
    exist: true,
    children: [
      {
        path: 'processOrderDetail',
        name: '加工单详情',
        hidden: true,
        isDetail: true,
        authOnlyNoSecret: authMap.AUTH_PAGE_ProcessDetailManage_MATERIAL,
      },
    ],
  },
  {
    path: 'saleOrder',
    icon: 'icon-xiaoshoudingdan',
    name: '销售订单管理',
    children: [
      {
        path: 'originalOrderManage',
        name: '销售原单管理',
        exist: true,
        authOnlyNoSecret: authMap.AUTH_PAGE_OriginalOrderManage_NOSECRET,
        children: [
          {
            path: 'originalOrderDetail',
            name: '销售原单详情',
            hidden: true,
            isDetail: true,
            authOnlySecret: authMap.AUTH_FUNC_OriginalOrderDetail_SECRET,
            authOnlyNoSecret: authMap.AUTH_PAGE_OriginalOrderDetail_NOSECRET,
          },
        ],
      },
      {
        path: 'saleOrderManage',
        name: '销售订单管理',
        exist: true,
        authOnlyNoSecret: authMap.AUTH_PAGE_SaleOrderManage_NOSECRET,
        children: [
          {
            path: 'saleOrderDetail',
            name: '销售订单详情',
            hidden: true,
            isDetail: true,
            authOnlySecret: authMap.AUTH_PAGE_SaleOrderDetail_SECRET,
            authOnlyNoSecret: authMap.AUTH_PAGE_SaleOrderDetail_NOSECRET,
          },
        ],
      },
    ],
  },
  {
    path: 'stockCenter',
    icon: 'icon-kucunzhongxin',
    name: '库存中心',
    children: [
      {
        path: 'companyWarehouseStockManage',
        name: '货主仓库库存管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_CompanyWarehouseStockManage_NOSECRET,
      },
      {
        path: 'companyStockmanage',
        name: '货主库存管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_CompanyStockManage_NOSECRET,
      },
      {
        path: 'transactionFlow',
        name: '库存交易流水',
        authOnlyNoSecret: authMap.AUTH_PAGE_transactionFlow_NOSECRET,
      },
      {
        path: 'stockPicture',
        name: '库存快照',
        authOnlyNoSecret: authMap.AUTH_PAGE_StockPicture,
      },
      {
        path: 'stockInnerDiffBill',
        name: '内部对账差异',
        authOnlyNoSecret: authMap.AUTH_PAGE_StockInnerDiffBill,
      },
      {
        path: 'inventoryReconciliation',
        name: '库存对账',
        authOnlyNoSecret: authMap.AUTH_PAGE_InventoryReconciliation,
      },
      {
        path: 'inventoryVersionDetail',
        name: '库存版本明细',
        authOnlyNoSecret: authMap.AUTH_PAGE_InventoryVersionDetail,
      },
      {
        path: 'inventoryAudit',
        name: '盘点审核',
        authOnlyNoSecret: authMap.AUTH_PAGE_InventoryAudit_NOSECRET,
      },
      {
        path: 'purchaseSaleManage',
        name: '进销存管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_PurchaseSaleManage_NOSECRET,
      },
    ],
  },
  {
    path: 'monitorCenter',
    icon: 'icon-normalbaowenjiankong',
    name: '监控管理',
    children: [
      {
        path: 'exceptMessage',
        name: '异常报文监控',
        authOnlyNoSecret: authMap.AUTH_PAGE_ExceptMessage_NOSECRET,
      },
    ],
  },
  {
    path: 'messageRepush',
    icon: 'icon-push_page-o',
    name: '报文重推',
    authOnlyNoSecret: authMap.AUTH_PAGE_sample,
  },
  {
    path: 'auth',
    icon: 'icon-quanxianguanli',
    name: '权限管理',
    children: [
      {
        path: 'functionManage',
        name: '功能模块',
        authOnlyNoSecret: authMap.AUTH_PAGE_FUNCTIONMANAGE,
      },
      {
        path: 'roleManage',
        name: '角色管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_ROLEMANAGE,
      },
    ],
  },
  {
    path: 'publishManage',
    icon: 'icon-fabuguanli',
    name: '发布管理',
    authOnlyNoSecret: authMap.AUTH_MENU_AnnounceManage,
    children: [
      {
        path: 'announceManage',
        name: '公告管理',
        authOnlyNoSecret: authMap.AUTH_PAGE_AnnounceManage,
      },
    ],
  },
  {
    path: 'helpCenter',
    hidden: true,
    name: '帮助中心',
    authOnlyNoSecret: authMap.AUTH_PAGE_sample,
  },
  // -- APPEND HERE --
  // FIXME: 上面的标识用于plop模板匹配,请勿轻易修改或者删除,合并时也请将语句置于句尾
];

// 不需要在菜单栏展示的模块，在这里配置(现在还没有处理权限的逻辑)
const menusNoSidebar: Array<IMenuDataItem> = [
  {
    path: 'login',
    name: '登录',
    auth: authMap.AUTH_PAGE_sample,
  },
  {
    path: 'logout',
    name: '登出',
    auth: authMap.AUTH_PAGE_sample,
  },
];

export default config;
export { menusNoSidebar };
