/*
 * @Author: 李淳
 * @Date: 2020-06-21 12:23:57
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-25 19:50:46
 * @Description: 项目基础配置
 */

/**
 * 表格页码基础配置
 */
export const baseTableConf = {
  pageSize: 50 as const,
  pageSizeOptions: ['25', '50', '100'],
};

/**
 * 支持的语言环境列表
 * @description 之后新增语言环境只需要在这里添加即可
 */
const locales = ['zh-CN', 'en-US'] as const;
/** 语言环境和cookie所用字段映射 */
const cookieFieldMap = locales.reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: cur.replace(/-/, '_'),
  }),
  {} as Record<(typeof locales)[number], string>,
);
/** cookie所用字段和语言环境映射 */
const reversedCookieFieldMap = Object.fromEntries(Object.entries(cookieFieldMap).map(([key, value]) => [value, key]));
/**
 * 国际化基础配置
 */
export const i18nConf = {
  enable: true,
  defaultLocale: 'zh-CN' as const,
  locales,
  cookieName: 'sofa-lang' as const,
  cookieFieldMap,
  reversedCookieFieldMap,
};

/**
 * 项目基础配置
 */
export default {
  name: 'Janus',
  entrance: '/homePage',
  // iconfont项目，如若需要权限，联系张宁
  // https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=2886751
  // 下面的两个配置项已经不用了
  // aliIconFontSourceURL: 'http://at.alicdn.com/t/c/font_2886751_ayb3puxa134.js',
  // iconFontScriptURL: '/static/js/iconfont.js',
};
